import { Dropdown, Table, Badge } from "react-bootstrap";
import { FaCheck, FaPen, FaPlus, FaXmark, FaUserPlus, FaUserMinus, FaAngleRight } from "react-icons/fa6";
import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ThemeNavbar from "../components/themeNavbar";
import ThemeButton from "../components/themeButton";
import AddUserForm from "../modals/addUserForm";
import AddGroupForm from "../modals/addGroupForm";
import AddUsersToGroupModal from "../modals/addUsersToGroupModal";
import RemoveUsersFromGroupModal from "../modals/removeUsersFromGroupModal";
import "../styles/profilePageStyles.css";
import {
  getAllUsers, updateUserRole, getAllGroups, getUserByEmail
} from "../apis/userApis";

const ROLES = ["admin", "user", "defunct"];

function ProfilePage() {

  const [profile, setProfile] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);
  const [serverError, setServerError] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [addUserResponse, setAddUserResponse] = useState(null);
  const [groupsData, setGroupsData] = useState([]);
  const [showAddGroupModal, setShowAddGroupModal] = useState(false);
  const [addGroupResponse, setAddGroupResponse] = useState(null);
  const [showAddUsersToGroupModal, setShowAddUsersToGroupModal] = useState(false);
  const [addUsersToGroupResponse, setAddUsersToGroupResponse] = useState(null);
  const [showRemoveUsersFromGroupModal, setShowRemoveUsersFromGroupModal] = useState(false);
  const [removeUsersFromGroupResponse, setRemoveUsersFromGroupResponse] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);

  const usersTableRef = useRef(null);
  const groupsTableRef = useRef(null);

  /**
   * Fetches all users using the provided access token.
   *
   * @return {void} This function does not return anything.
   */
  const fetchAllUsers = () => {
    getAllUsers()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setUsersData(response.data);
        } else {
          handleFetchError(response.status);
        }
      })
      .catch((error) => {
        console.error(error);
        handleFetchError(503);
      });
  };

  const fetchAllGroups = () => {
    getAllGroups()
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setGroupsData(response.data);
        } else {
          handleFetchError(response.status);
        }
      })
      .catch((error) => {
        console.error(error);
        handleFetchError(503);
      });
  };

  const fetchUserProfile = (email) => {
    console.log("Fetching profile for: " + email);
    getUserByEmail(email)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setProfile(response.data.user);
          console.log(response.data.user);
          if (response.data.user.user_role === "admin") {
            fetchAllGroups();
          }
        } else {
          handleFetchError(response.status);
        }
      })
      .catch((error) => {
        console.error(error);
        handleFetchError(503);
      });
  };

  /**
    * Handles fetch errors based on the status code.
    *
    * @param {number} status - The status code of the fetch error.
    * @return {void} This function does not return anything.
    */
  const handleFetchError = (status) => {
    switch (status) {
      case 400:
        setServerError("400 Bad Request");
        break;
      case 401:
        setServerError("401 Unauthorized Access");
        break;
      case 403:
        setServerError("403 Forbidden Access");
        break;
      case 404:
        setServerError("404 Not Found");
        break;
      case 429:
        setServerError("429 Too Many Requests");
        break;
      case 500:
        setServerError("500 Internal Server Error");
        break;
      case 503:
        setServerError("503 Service Unavailable");
        break;
      default:
        setServerError("500 Internal Server Error");
        break;
    }
  };

  /**
    * Handles different error scenarios based on the status code provided.
    *
    * @param {number} status - The status code indicating the type of error.
    * @return {void} This function does not return anything directly but alerts the appropriate error message.
    */
  const handleRoleChangeError = (status) => {
    switch (status) {
      case 400:
        alert("Failed to update role. Invalid role.\nPlease select a role from the dropdown list.");
        break;
      case 401:
        alert("Failed to update role. Unauthorized access.");
        break;
      case 403:
        alert("Failed to update role. Forbidden access.\nYou do not have permission to update this user's role.");
        break;
      case 404:
        alert("Failed to update role. User not found.");
        break;
      case 500:
        alert("Failed to update role. Internal server error.");
        break;
      default:
        alert("Failed to update role. Error: " + status);
        break;
    }
  };

  useEffect(() => {
    // console.log("useEffect");
    const userFromStorage = JSON.parse(localStorage.getItem("user"));
    if (!userFromStorage) {
      setServerError("401 Unauthorized Access"); // Set 401 if user is empty
    } else {
      // console.log(userFromStorage);
      fetchUserProfile(userFromStorage.email);
      fetchAllUsers();
      window.addEventListener("resize", handleResize);
    }
  }, []);

  const handleResize = () => {
    if (window.innerWidth < 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const scrollTableRight = (tableRef) => {
    if (tableRef.current) {
      const scrollContainer = tableRef.current;
      const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

      scrollContainer.scrollTo({
        left: maxScrollLeft,
        behavior: "smooth"
      });
    }
  };

  const handleRoleChange = (email) => {
    if (!selectedRole) {
      alert("Please select a role.");
      return;
    }

    updateUserRole(email, selectedRole)
      .then((response) => {
        if (response.status === 200) {
          // Role change successful
          alert("Role updated successfully.");
          // Refresh user data
          fetchAllUsers();
        } else {
          // Role change unsuccessful
          handleRoleChangeError(response.status);
        }
      })
      .catch((error) => {
        console.error(error);
        // Role change unsuccessful
        alert("Failed to update role.");
      });
  };

  const handleAddUser = () => {
    setShowAddUserModal(true);
  };

  const handleCloseAddUserModal = () => {
    console.log(addUserResponse);
    if (addUserResponse === 401) {
      setProfile(null);
      setServerError("401 Unauthorized Access");
    }
    else {
      fetchAllUsers();
    }
    setShowAddUserModal(false);
  };

  const handleAddGroup = () => {
    setShowAddGroupModal(true);
  };

  const handleCloseAddGroupModal = () => {
    console.log(addGroupResponse);
    if (addGroupResponse >= 399) {
      setServerError("500 Internal Server Error");
    }
    else {
      fetchUserProfile(profile.email);
      fetchAllGroups();
      fetchAllUsers();
    }
    setShowAddGroupModal(false);
  };

  const handleEdit = (userId, currentRole) => {
    setEditMode(true);
    setSelectedUserId(userId);
    setSelectedRole(currentRole);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setSelectedUserId(null);
    setSelectedRole(null);
  };

  const handleSaveEdit = () => {
    if (!selectedRole) {
      alert("Please select a role.");
      return;
    }

    handleRoleChange(usersData.find(user => user.id === selectedUserId).email);

    setEditMode(false);
    setSelectedUserId(null);
    setSelectedRole(null);
  };

  const handleAddUserToGroup = async (groupId) => {
    setSelectedGroupId(groupId);
    setShowAddUsersToGroupModal(true);
  };

  const handleRemoveUserFromGroup = async (groupId) => {
    setSelectedGroupId(groupId);
    setShowRemoveUsersFromGroupModal(true);
  };

  const handleCloseAddUsersToGroupsModal = () => {
    if (addUsersToGroupResponse >= 399) {
      handleFetchError(addUsersToGroupResponse);
    }
    setShowAddUsersToGroupModal(false);
    fetchAllUsers();
    setGroupsData([]);
    fetchAllGroups();
    setSelectedGroupId(null);
  };

  const handleCloseRemoveUsersFromGroupsModal = () => {
    if (removeUsersFromGroupResponse >= 399) {
      handleFetchError(removeUsersFromGroupResponse);
    }
    setShowRemoveUsersFromGroupModal(false);
    fetchAllUsers();
    setGroupsData([]);
    fetchAllGroups();
    setSelectedGroupId(null);
  };

  return (
    <div>
      {profile && !serverError && (
        <div>
          <ThemeNavbar />
          <div className="profile-section">
            <div>
              {profile.profile_pic && <img src={profile.profile_pic} alt="Profile" className="profile-picture" />}
            </div>
            <div className="profile-info">
              <div style={{ fontSize: isMobile ? "25px" : "50px", fontWeight: "bold" }}>Welcome, {profile.first_name} {profile.last_name}!</div>
              <div><b>Email:</b> {profile.email}</div>
              <div><b>Role:</b> {usersData.find(user => user.email === profile.email)?.user_role}</div>
              <div>
                <b>Groups: </b>
                {profile.groups && profile.groups.length > 0 ?
                  profile.groups.map((group, index) => (
                    <Badge key={index} pill bg="secondary" style={{ marginRight: "5px", }}>{group}</Badge>
                  ))
                  : "Not in any groups"
                }
              </div>
            </div>
          </div>
          <div style={{
            display: "flex", flexDirection: "row", alignItems: "center",
            justifyContent: "space-between", paddingLeft: "5px", paddingRight: "20px"
          }}>
            <h3>Registered Users</h3>
            <ThemeButton variant="outline-primary"
              onClick={handleAddUser} disabled={profile.user_role !== "admin"}>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FaPlus />
                Register New User
              </div>
            </ThemeButton>
          </div>
          {usersData.length > 0 && (
            <div className="table-container" ref={usersTableRef}>
              {isMobile && (
                <FaAngleRight onClick={() => scrollTableRight(usersTableRef)}
                  style={{ fontSize: "15px", zIndex: "100", position: "absolute", color: "#fff", left: "90%", marginTop: "2px" }} />
              )}
              <Table striped bordered className="curved-table" style={{ minWidth: "450px" }}>
                <thead style={{ fontSize: isMobile ? "14px" : "20px", height: isMobile ? "40px" : "60px" }}>
                  <tr>
                    <th style={{ backgroundColor: "#50011a", color: "white", width: "20%" }}>Name</th>
                    <th style={{ backgroundColor: "#50011a", color: "white", width: "25%" }}>Email</th>
                    <th style={{ backgroundColor: "#50011a", color: "white", width: "40%" }}>Groups</th>
                    <th style={{ backgroundColor: "#50011a", color: "white", width: "15%" }}>Role</th>
                  </tr>
                </thead>
                <tbody style={{ fontSize: isMobile ? "12px" : "18px", minWidth: "450px" }}>
                  {usersData.map((user) => (
                    <tr key={user.id}>
                      <td>
                        {user.email === profile.email ?
                          <span>{user.first_name} {user.last_name} <strong>(You)</strong></span> :
                          `${user.first_name} ${user.last_name}`
                        }
                      </td>
                      <td>{user.email}</td>
                      <td>
                        {profile.user_role !== "admin" ? (
                          <>
                            {user.email === profile.email ? (
                              <>
                                {profile.groups.length > 0 ?
                                  profile.groups.map((group, index) => (
                                    <Badge key={index} pill bg="secondary" style={{ marginRight: "5px" }}>{group}</Badge>
                                  ))
                                  : "Not in any groups"}
                              </>
                            ) : (
                              <p style={{ fontStyle: "italic", fontSize: "14px" }}>
                                Need admin level access to view groups of other users
                              </p>
                            )
                            }
                          </>
                        ) : (
                          <>
                            {user.groups.length > 0 ?
                              user.groups.map((group, index) => (
                                <Badge key={index} pill bg="secondary" style={{ marginRight: "5px" }}>{group}</Badge>
                              ))
                              : "Not in any groups"}
                          </>
                        )}

                      </td>
                      <td>
                        {editMode && selectedUserId === user.id ? (
                          <div style={{ display: "grid", gap: "10px", }}>
                            <Dropdown style={{ gridRow: 1, gridColumn: 1 }}>
                              <Dropdown.Toggle variant="outline-primary"
                                style={{
                                  "--bs-btn-color": "#50011a",
                                  "--bs-btn-border-color": "#50011a",
                                  "--bs-btn-hover-color": "#fff",
                                  "--bs-btn-hover-bg": "#50011a",
                                  "--bs-btn-hover-border-color": "#50011a",
                                  "--bs-btn-active-color": "#fff",
                                  "--bs-btn-active-bg": "#50011a",
                                  "--bs-btn-active-border-color": "#50011a",
                                  "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
                                  "--bs-btn-disabled-color": "#50011a",
                                  "--bs-btn-disabled-bg": "transparent",
                                  "--bs-btn-disabled-border-color": "#50011a",
                                  fontSize: isMobile ? "12px" : "18px"
                                }} >
                                {selectedRole}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {ROLES.map(role => (
                                  <Dropdown.Item key={role} onClick={() => setSelectedRole(role)}
                                    style={{ "--bs-dropdown-link-active-bg": "#50011a", }}>
                                    {role}
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                            <div style={{ gridRow: 1, gridColumn: 2, display: "flex", justifyContent: "flex-end" }}>
                              <ThemeButton variant="outline-primary"
                                style={{ marginRight: "10px", fontSize: isMobile ? "8px" : "18px" }} onClick={handleSaveEdit}>
                                <FaCheck />
                              </ThemeButton>
                              <ThemeButton variant="outline-primary"
                                style={{ marginRight: "10px", fontSize: isMobile ? "8px" : "18px" }} onClick={handleCancelEdit}>
                                <FaXmark />
                              </ThemeButton>
                            </div>

                          </ div>
                        ) : (
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {user.user_role}
                            <ThemeButton variant="outline-primary" style={{ marginRight: "15px", marginLeft: "5px", fontSize: isMobile ? "8px" : "18px" }}
                              onClick={() => handleEdit(user.id, user.user_role)}
                              disabled={user.email === profile.email}>
                              <FaPen />
                            </ThemeButton>
                          </ div>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
          {profile.user_role === "admin" &&
            <>
              <div style={{
                display: "flex", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between", paddingLeft: "5px", paddingRight: "20px"
              }}>
                <h3>Groups</h3>
                <ThemeButton
                  variant="outline-primary" onClick={handleAddGroup} disabled={profile.user_role !== "admin"}>
                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                    <FaPlus />
                    Make New Group
                  </div>
                </ThemeButton>
              </div>
              <div className="table-container" ref={groupsTableRef}>
                {isMobile && (
                  <FaAngleRight onClick={() => scrollTableRight(groupsTableRef)}
                    style={{ fontSize: "15px", zIndex: "100", position: "absolute", color: "#fff", left: "90%", marginTop: "2px" }} />
                )}
                <Table striped bordered className="curved-table" style={{ minWidth: "450px" }}>
                  <thead style={{ fontSize: isMobile ? "12px" : "20px", height: isMobile ? "40px" : "60px" }}>
                    <tr>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "10%" }}>Group ID</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "20%" }}>Group Name</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "45%" }}>Users</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "25%" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: isMobile ? "12px" : "18px" }}>
                    {groupsData && groupsData.map((group) => (
                      <tr key={group.id}>
                        <td>{group.id}</td>
                        <td>{group.name}</td>
                        <td>
                          {group.users.map((user) => (
                            <Badge key={user} bg="dark" style={{ marginRight: "5px" }}>
                              {user}
                            </Badge>
                          ))}
                          {group.users.length === 0 && <Badge variant="light">None</Badge>}
                        </td>
                        <td style={{ display: "flex", gap: "10px" }}>
                          <ThemeButton variant="outline-primary"
                            disabled={profile.user_role !== "admin" || !profile.groups_ids.includes(group.id)}
                            onClick={() => handleAddUserToGroup(group.id)}>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                              <FaUserPlus /> {isMobile ? <></> : <div style={{ whiteSpace: "nowrap" }}>Add Users</div>}
                            </ div>
                          </ThemeButton>
                          <ThemeButton variant="outline-primary"
                            disabled={profile.user_role !== "admin" || !profile.groups_ids.includes(group.id)}
                            onClick={() => handleRemoveUserFromGroup(group.id)}>
                            <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                              <FaUserMinus /> {isMobile ? <></> : <div style={{ whiteSpace: "nowrap" }}>Remove Users</div>}
                            </ div>
                          </ThemeButton>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>}
        </div>
      )
      }
      {serverError && serverError === "401 Unauthorized Access" && (
        <div className='site-container'>
          <header className="site-header">
            <div className="title-area">
              <h1 className="site-title">
                <a href="https://birdshot.tamu.edu/">Birdshot Center</a>
              </h1>
            </div>
            <div className="widget-area header-widget-area">
              <a id="site-logo" href="https://birdshot.tamu.edu" style={{ display: "block", maxWidth: "350px" }}>
                <img id="custom-header" src="https://birdshot.tamu.edu/wp-content/themes/coe-theme/images/logos/coe/logos-horizontal/ENGINEERING_logo_HWHT.png" alt="Texas A&M University College of Engineering" />
              </a>
            </div>
          </header>
          <body className='site-body'>
            <h2 className='body-title'>Automated Google Web Form Creator and Manager</h2>
            <p className='login-response'>401 Unauthorized Access</p>
            <p>Please <Link to="/" className='link'>Login</Link> to continue.</p>
          </body>
        </div>
      )}
      {serverError && serverError !== "401 Unauthorized Access" && (
        <div>
          <ThemeNavbar></ThemeNavbar>
          <div className='server-error-container'>
            {serverError === "403 Forbidden Access"
              ? (
                <>
                  <p className='login-response'>
                    {serverError}
                  </p>
                  <p>
                    Logout of the website and login again, or ask admin <a href='mailto:attar.v@tamu.edu'>attar.v@tamu.edu</a> for permission
                  </p>
                </>
              )
              : (
                <>
                  <p className='login-response'>
                    {serverError}
                  </p>
                  <p>
                    Contact our development team at <a href='mailto:divyanshu@tamu.edu'>divyanshu@tamu.edu</a>, and <a href='mailto:c2003@tamu.edu'>c2003@tamu.edu</a>
                  </p>
                </>
              )
            }
          </div>
        </div>
      )}
      <AddUserForm show={showAddUserModal} handleClose={handleCloseAddUserModal}
        setAddUserResponse={setAddUserResponse} />
      <AddGroupForm
        show={showAddGroupModal}
        handleClose={handleCloseAddGroupModal}
        setAddGroupResponse={setAddGroupResponse}
      />
      <div>
        <AddUsersToGroupModal
          show={showAddUsersToGroupModal}
          onHide={handleCloseAddUsersToGroupsModal}
          groupId={selectedGroupId}
          addUserToGroupsResponse={setAddUsersToGroupResponse} />
      </div>
      <div>
        <RemoveUsersFromGroupModal
          show={showRemoveUsersFromGroupModal}
          onHide={handleCloseRemoveUsersFromGroupsModal}
          groupId={selectedGroupId}
          removeUserFromGroupsResponse={setRemoveUsersFromGroupResponse} />
      </div>
    </div >
  );
}

export default ProfilePage;
