const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";

/**
 * Retrieves all responses from the server.
 *
 * @return {Object} An object containing the response status and data if successful, otherwise status only.
 */
export const getAllResponses = async () => {
  try {
    const response = await fetch(`${baseURL}/api/response/getAllResponses`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves a response by its ID.
 *
 * @param {string} id - The ID of the response.
 * @return {Promise<Object>} A Promise that resolves to an object containing the response status and data if successful, otherwise status only.
 */
export const getResponseById = async (id) => {
  try {
    const response = await fetch(`${baseURL}/api/response/getResponseById?id=${id}`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Sends a POST request to the server to submit a response.
 *
 * @param {Object} data - The response data to be submitted.
 * @param {string} googleFolderID - The ID of the Google folder associated with the response.
 * @return {Promise<Object>} A Promise that resolves to an object containing the response status and data if successful, otherwise status only.
 */
export const postResponse = async (data, googleFolderID) => {
  try {
    const response = await fetch(`${baseURL}/api/response/postResponse?googleFolderID=${googleFolderID}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json()
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Initiates a file upload by sending a POST request to the server.
 *
 * @param {string} fileName - The name of the file to be uploaded.
 * @param {string} fileType - The type of the file being uploaded.
 * @param {number} fileSize - The size of the file to be uploaded.
 * @param {string} googleFolderID - The ID of the Google folder for the upload.
 * @return {Promise} A Promise that resolves to the response data if successful, otherwise throws an error.
 */
export const initiateFileUpload = async (fileName, fileType, fileSize, googleFolderID) => {
  try {
    const response = await fetch(`${baseURL}/api/response/initiateUpload`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ fileName, fileType, fileSize, googleFolderID }),
    });
    if (response.status === 200) {
      return await response.json();
    }
    throw new Error(`Server responded with status ${response.status}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Uploads a chunk of a file to the server.
 *
 * @param {string} uploadId - The ID of the upload.
 * @param {ArrayBuffer} chunk - The chunk of the file to be uploaded.
 * @param {number} start - The start index of the chunk in the file.
 * @param {number} end - The end index of the chunk in the file.
 * @param {number} fileSize - The total size of the file being uploaded.
 */
export const uploadFileChunk = async (uploadId, chunk, start, end, fileSize) => {
  try {
    const response = await fetch(`${baseURL}/api/response/uploadChunk`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uploadId, chunk: Array.from(new Uint8Array(chunk)), start, end, fileSize }),
    });
    if (response.status !== 200) {
      throw new Error(`Server responded with status ${response.status}`);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
};

/**
 * Completes a file upload by sending a POST request to the server.
 *
 * @param {string} uploadId - The ID of the upload.
 * @return {Promise} A Promise that resolves to the response data if successful, otherwise throws an error.
 */
export const completeFileUpload = async (uploadId, googleFolderID) => {
  try {
    const response = await fetch(`${baseURL}/api/response/completeUpload`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ uploadId, googleFolderID }),
    });
    if (response.status === 200) {
      return await response.json();
    }
    throw new Error(`Server responded with status ${response.status}`);
  } catch (error) {
    console.error(error);
    throw error;
  }
};
