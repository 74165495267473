import React, { useEffect, useState } from "react";
import { Modal, Spinner, Badge, Dropdown } from "react-bootstrap";
import { pushStructureToDrive } from "../apis/folderApis";
import { getUserByEmail } from "../apis/userApis";
import { FaPlus, FaXmark, FaAngleUp } from "react-icons/fa6";
import ThemeButton from "../components/themeButton";

const PushFolderStructureModal = ({ show, onHide, folderStructureId, setPushFolderStructureReponse }) => {
  const [uploading, setUploading] = useState(false);
  const [profile, setProfile] = useState({});
  const [userGroups, setUserGroups] = useState([]);
  const [folderGroups, setFolderGroups] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  const fetchUserProfile = (email) => {
    console.log("Fetching profile for: " + email);
    getUserByEmail(email)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setProfile(response.data.user);
          if (response.data.user.groups_ids && response.data.user.groups_ids.length > 0) {
            const groupTuples = response.data.user.groups_ids.map((id, index) => [id, response.data.user.groups[index]]);
            setUserGroups(groupTuples);
            setFolderGroups(groupTuples);
          }
        } else {
          setPushFolderStructureReponse(response.status);
          resetState();
          onHide();
        }
      })
      .catch((error) => {
        console.error(error);
        setPushFolderStructureReponse(503);
        resetState();
        onHide();
      });
  };

  const handleGroupAdditionRequest = (groupId, groupName) => {
    // Check if the group is already in folderGroups
    if (!folderGroups.some(([id]) => id === groupId)) {
      setFolderGroups([...folderGroups, [groupId, groupName]]);
    }
  };

  const handleGroupRemoval = (groupIdToRemove) => {
    setFolderGroups(folderGroups.filter(([id]) => id !== groupIdToRemove));
  };

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        fetchUserProfile(userFromStorage.email);
        setPushFolderStructureReponse(200);
        window.addEventListener("resize", handleResize);
      } else {
        setPushFolderStructureReponse(401);
        onHide();
      }
    }
  }, [show, onHide, setPushFolderStructureReponse]);

  const handleResize = () => {
    if (window.innerWidth < 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const handleDrivePush = async () => {
    setUploading(true);
    const groupIds = folderGroups.map(([id]) => id);
    console.log(groupIds);
    pushStructureToDrive(folderStructureId, groupIds)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setUploading(false);
          window.alert("Successfully pushed to Google Drive!");
          setPushFolderStructureReponse(200);
          resetState();
          onHide();
        } else {
          console.log(response);
          setUploading(false);
          window.alert("Failed to push to Google Drive. Error: " + response.status);
        }
      })
      .catch((error) => {
        window.alert("An error occurred while pushing to Google Drive: " + error);
      });
  };

  const resetState = () => {
    setUploading(false);
  };

  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} centered >
      <Modal.Header closeButton>
        <Modal.Title>Push Folder Structure</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {uploading ? (
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Spinner animation="border" role="status" style={{ marginRight: "10px" }}>
            </Spinner>
            <span>Uploading...</span>
          </div>
        ) : (
          <div>
            <p style={{ marginBottom: "5px", fontSize: isMobile ? "14px" : "inherit" }}><strong>Owner:</strong> {profile.email}</p>
            <strong style={{ fontSize: isMobile ? "14px" : "inherit" }}>Which groups would access this folder structure?</strong>
            <p style={{ marginBottom: "5px", fontSize: isMobile ? "14px" : "inherit" }}><strong>Selected Groups: </strong> {folderGroups.length > 0 ?
              folderGroups.map(([id, name]) => (
                <Badge key={id} pill bg="primary" style={{ marginRight: "5px", display: "inline-flex", alignItems: "center" }}>
                  {name}
                  <FaXmark
                    onClick={() => handleGroupRemoval(id)}
                    style={{ marginLeft: "5px", cursor: "pointer" }}
                  />
                </Badge>
              )) : <>No groups selected <strong style={{ fontSize: isMobile ? "10px" : "12px" }}>(All users will have access)</strong></>}
            </p>
            <Dropdown>
              <Dropdown.Toggle variant="outline-primary" style={{
                "--bs-btn-color": "#50011a",
                "--bs-btn-border-color": "#50011a",
                "--bs-btn-hover-color": "#fff",
                "--bs-btn-hover-bg": "#50011a",
                "--bs-btn-hover-border-color": "#50011a",
                "--bs-btn-active-color": "#fff",
                "--bs-btn-active-bg": "#50011a",
                "--bs-btn-active-border-color": "#50011a",
                "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
                "--bs-btn-disabled-color": "#50011a",
                "--bs-btn-disabled-bg": "transparent",
                "--bs-btn-disabled-border-color": "#50011a",
                fontSize: "12px",
                padding: "0 5px",
                marginLeft: "5px",
              }}>
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <FaPlus /> Give Access to Group
                </div>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {userGroups.map(([id, name]) => (
                  <Dropdown.Item key={id} onClick={() => handleGroupAdditionRequest(id, name)}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {name}
                    </div>
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <ThemeButton variant="outline-primary" onClick={handleDrivePush}
          disabled={uploading}>
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FaAngleUp />
            Push
          </div>
        </ThemeButton>
      </Modal.Footer>
    </Modal>
  );
};

export default PushFolderStructureModal;