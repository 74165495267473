import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaFolder, FaFolderOpen, FaRegSquare, FaRegSquarePlus, FaRegSquareCheck, FaRegSquareMinus } from "react-icons/fa6";
import { getChildFoldersOfUploadedFolder, addFormToFolder, removeFormFromFolder } from "../apis/folderApis";

const FormFolderSelectionModal = ({ show, onHide, formID, setFolderSelectorResponse }) => {

  const [folderStructure, setFolderStructure] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState(null);
  const [expandedFolders, setExpandedFolders] = useState([]);
  const [hoveredFolder, setHoveredFolder] = useState(null);
  const rootFolderName = "Drive";
  const rootFolderGoogleID = "***";

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        setProfile(userFromStorage);
        setFolderSelectorResponse(200);
      } else {
        setFolderSelectorResponse(401);
        onHide();
      }
    }
  }, [show, onHide]);


  useEffect(() => {
    if (profile) {
      const EmptyFolderStructure = {
        name: rootFolderName,
        google_id: rootFolderGoogleID,
        children: []
      };
      setFolderStructure(EmptyFolderStructure);
      fetchFolderStructure(EmptyFolderStructure);
    }
  }, [profile]);


  const fetchFolderStructure = async (parentFolder) => {
    setIsLoading(true);
    let parentFolderID;
    if (!parentFolder.id) {
      parentFolderID = -1;
    }
    else {
      parentFolderID = parentFolder.id;
    }
    const fetchedFolderStructure = await getChildFoldersOfUploadedFolder(parentFolderID);
    if (fetchedFolderStructure.status >= 200 && fetchedFolderStructure.status < 300) {
      // console.log(fetchedFolderStructure.data);
      parentFolder.children = fetchedFolderStructure.data;
    } else {
      setFolderSelectorResponse(fetchedFolderStructure.status);
      resetState();
      onHide();
    }
    setIsLoading(false);
  };

  const toggleFolder = async (folderName) => {
    // console.log("folderName", folderName);
    if (expandedFolders.includes(folderName)) {
      const newExpandedFolders = expandedFolders.filter((folder) => folder !== folderName);
      setExpandedFolders(newExpandedFolders);
    } else {
      const specificFolder = findFolderWithGoogleID(folderStructure, folderName);
      if (specificFolder && (!specificFolder.children || specificFolder.children.length === 0)) {
        await fetchFolderStructure(specificFolder);
      }
      const newExpandedFolders = [...expandedFolders, folderName];
      setExpandedFolders(newExpandedFolders);
    }
  };

  const findFolderWithGoogleID = (folderStructureToSearch, googleID) => {
    // console.log("folderStructureToSearch: ", folderStructureToSearch);
    // console.log("googleID: ", googleID);
    if (folderStructureToSearch.google_id === googleID) {
      return folderStructureToSearch;
    } else if (folderStructureToSearch.children) {
      for (const child of folderStructureToSearch.children) {
        const foundFolder = findFolderWithGoogleID(child, googleID);
        if (foundFolder) {
          return foundFolder;
        }
      }
    }
    return null;
  };

  const handleAddFormToFolder = async (folderID) => {
    setIsLoading(true);
    const response = await addFormToFolder(folderID, formID);
    if (response.status >= 200 && response.status < 300) {
      setFolderSelectorResponse(200);


    } else {
      setFolderSelectorResponse(response.status);
      alert("Could not add form to folder. Error: ", response);
      resetState();
      onHide();
    }
    setIsLoading(false);
  };

  const handleRemoveFormFromFolder = async (folderID) => {
    setIsLoading(true);
    const response = await removeFormFromFolder(folderID, formID);
    if (response.status >= 200 && response.status < 300) {
      setFolderSelectorResponse(200);
    } else {
      setFolderSelectorResponse(response.status);
      alert("Could not remove form from folder. Error: ", response);
      resetState();
      onHide();
    }
    setIsLoading(false);
  };

  const renderFolderStructure = (folder) => {
    // console.log(folder);
    const folderPath = `${folder.google_id}`;
    const isExpanded = expandedFolders.includes(folderPath);
    const isHovered = hoveredFolder === folderPath;

    const handleAddFormToFolderClick = (e) => {
      e.stopPropagation(); // Stop event propagation
      handleAddFormToFolder(folder.id);
      folder.form_ids = folder.form_ids || [];
      folder.form_ids.push(formID);
    };

    const handleRemoveFormFromFolderClick = (e) => {
      e.stopPropagation(); // Stop event propagation
      handleRemoveFormFromFolder(folder.id);
      folder.form_ids = folder.form_ids || [];
      const index = folder.form_ids.indexOf(formID);
      if (index > -1) {
        folder.form_ids.splice(index, 1);
      }
    };

    return (
      <div key={folderPath} style={{ marginBottom: "5px", paddingLeft: "10px", borderLeft: "2px solid transparent" }}>
        <div
          style={{
            display: "flex", alignItems: "center", cursor: "pointer", padding: "5px", borderRadius: "3px",
            transition: "background-color 0.3s", backgroundColor: isHovered ? "#f0f0f0" : "transparent"
          }}
          onMouseEnter={() => setHoveredFolder(folderPath)}
          onMouseLeave={() => setHoveredFolder(null)}
          onClick={() => toggleFolder(folderPath)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleFolder(folderPath);
            }
          }} role="button" tabIndex="0">
          <div style={{
            background: "none", border: "none", cursor: "pointer", marginRight: "5px",
            display: "flex", alignItems: "center",
          }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {isExpanded ? <FaFolderOpen /> : <FaFolder />}
              <div>
                <span style={{ marginLeft: "5px" }}>{folder.name}</span>
                <span style={{ marginLeft: "5px", fontSize: "10px", color: "gray" }}>(googleID: {folder.google_id})</span>
              </div>
            </div>
            <>
              {folder.google_id !== rootFolderGoogleID &&
                <div style={{ marginLeft: "15px", color: "#50011a" }}>
                  {folder.form_ids !== null && folder.form_ids.includes(formID) ? (
                    isHovered ?
                      <FaRegSquareMinus onClick={handleRemoveFormFromFolderClick} onKeyDown={handleRemoveFormFromFolderClick} role="button" tabIndex="0" /> :
                      <FaRegSquareCheck />
                  ) : (
                    isHovered ?
                      <FaRegSquarePlus onClick={handleAddFormToFolderClick} onKeyDown={handleAddFormToFolderClick} role="button" tabIndex="0" /> :
                      <FaRegSquare />
                  )}
                </div>
              }
            </>
          </div>

        </div>
        {isExpanded && folder.children && folder.children.length > 0 && (
          <div style={{ marginLeft: "20px" }}>
            {folder.children.map((child) => (
              <div key={child.google_id}>{renderFolderStructure(child, folderPath)}</div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const resetState = () => {
    setExpandedFolders([]);
    setHoveredFolder(null);
    setFolderStructure(null);
    setIsLoading(false);
  };

  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Form Folder Selection</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {folderStructure ? (
          <>
            <div style={{ marginBottom: "10px", display: "flex", alignItems: "center", justifyContent: "space-between", flexDirection: "row" }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <h5 style={{ marginBottom: "10px" }}>
                  Select Folders to add the form to.
                  <p style={{ fontSize: "14px", color: "gray", marginBottom: "0px" }}> (Form ID: {formID}) </ p>
                </h5>
              </div>
              {isLoading && (
                <div style={{ textAlign: "center" }}>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span className="sr-only">Processing...</span>
                </div>
              )}
            </div>
            <div>
              <div style={{ flex: 1, border: "1px solid #50011a", borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px", marginRight: "5px", overflow: "hidden" }}>
                <h5 style={{ backgroundColor: "#50011a", color: "white", padding: "10px", marginBlockEnd: "0px" }}>
                  Interactive Folder Selection
                </h5>
                <div style={{ maxHeight: "450px", overflowY: "auto", padding: "10px", paddingRight: "20px" }}>
                  {renderFolderStructure(folderStructure)}
                </div>
              </div>
            </div>
          </>

        ) : (
          <div>Error fetching folder structure</div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default FormFolderSelectionModal;