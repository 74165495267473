import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaFolder, FaFolderOpen } from "react-icons/fa6";
import { getFolderStructureById } from "../apis/folderApis";

const ViewFolderStructureModal = ({ show, onHide, folderStructureID, setViewFolderStructureResponse }) => {
  const [folderStructure, setFolderStructure] = useState(null);
  const [structureName, setStructureName] = useState("");
  const [numberOfFolders, setNumberOfFolders] = useState(0);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedFolders, setExpandedFolders] = useState([]);
  const [hoveredFolder, setHoveredFolder] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);


  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        setProfile(userFromStorage);
        setViewFolderStructureResponse(200);
        window.addEventListener("resize", handleResize);
      } else {
        setViewFolderStructureResponse(401);
        onHide();
      }
    }
  }, [show, onHide, setViewFolderStructureResponse]);

  const handleResize = () => {
    if (window.innerWidth < 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    if (show && profile) {
      fetchFolderStructure();
    }
  }, [show, profile]);

  const fetchFolderStructure = async () => {
    setIsLoading(true);
    const response = await getFolderStructureById(folderStructureID);
    if (response.status >= 200 && response.status < 300) {
      const folderData = response.data[0];
      setFolderStructure(folderData.structure);
      setStructureName(folderData.name);
      setNumberOfFolders(folderData.number_of_folders);
    } else {
      setViewFolderStructureResponse(response.status);
      onHide();
    }
    setIsLoading(false);
  };

  const generateTextRepresentation = (folder, prefix = "", level = 0) => {
    level += 1;
    let text = `${prefix.slice(0, -4 + (level - 1) * -3)}${folder.name}\n`;
    if (folder.children && folder.children.length > 0) {
      prefix += "";
      folder.children.forEach((child, index, children) => {
        text += prefix;
        if (index === children.length - 1) {
          text += "└───";
          prefix += "    ";
        } else {
          text += "│───";
          prefix += "│   ";
        }
        text += generateTextRepresentation(child, prefix, level);
        prefix = prefix.slice(0, -4);
      });
    }

    return text;
  };

  const toggleFolder = (folderName) => {
    setExpandedFolders((prevExpandedFolders) => {
      if (prevExpandedFolders.includes(folderName)) {
        return prevExpandedFolders.filter((folder) => folder !== folderName);
      } else {
        return [...prevExpandedFolders, folderName];
      }
    });
  };

  const renderFolderStructure = (folder, parentPath = "") => {
    const folderPath = `${parentPath}/${folder.name}`;
    const isExpanded = expandedFolders.includes(folderPath);
    const isHovered = hoveredFolder === folderPath;

    return (
      <div key={folderPath} style={{ marginBottom: "5px", paddingLeft: "10px", borderLeft: "2px solid transparent" }}>
        <div
          style={{
            display: "flex", alignItems: "center", cursor: "pointer", padding: "5px",
            borderRadius: "3px", transition: "background-color 0.3s",
            backgroundColor: isHovered ? "#f0f0f0" : "transparent",
          }}
          onMouseEnter={() => setHoveredFolder(folderPath)}
          onMouseLeave={() => setHoveredFolder(null)}
          onClick={() => toggleFolder(folderPath)}
          onKeyDown={(event) => {
            if (event.key === "Enter") {
              toggleFolder(folderPath);
            }
          }}
          role="button"
          tabIndex="0"
        >
          <div style={{
            background: "none", border: "none", cursor: "pointer", marginRight: "5px",
            display: "flex", alignItems: "center"
          }}>
            {isExpanded ? <FaFolderOpen /> : <FaFolder />}
            <span style={{ marginLeft: "5px" }}>{folder.name}</span>
          </div>

        </div>
        {isExpanded && folder.children && folder.children.length > 0 && (
          <div style={{ marginLeft: "20px" }}>
            {folder.children.map((child) => (
              <div key={child.name}>{renderFolderStructure(child, folderPath)}</div>
            ))}
          </div>
        )}
      </div>
    );
  };

  const resetState = () => {
    setExpandedFolders([]);
    setHoveredFolder(null);
  };


  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: isMobile ? "18px" : "" }}>View Folder Structure</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "650px", overflowY: "auto", maxWidth: "800px", overflowX: "auto" }}>
        {isLoading ? (
          <div style={{ textAlign: "center" }}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span className="sr-only">Loading...</span>
          </div>
        ) : folderStructure ? (
          <div>
            <div style={{ display: "flex" }}>
              <div>
                <h5 style={{ fontSize: isMobile ? "18px" : "" }}>Structure Name: {structureName}</h5>
                <p style={{ fontSize: isMobile ? "14px" : "" }}>Number of Folders: {numberOfFolders}</p>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, border: "1px solid #50011a", borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px", marginRight: "5px", overflow: "hidden" }}>
                <h5 style={{
                  backgroundColor: "#50011a", color: "white", padding: "10px",
                  marginBlockEnd: "0px", fontSize: isMobile ? "14px" : "", whiteSpace: "nowrap",
                  overflowX: "auto"
                }}>
                  Interactive Collapsible Structure
                </h5>
                <div style={{
                  maxHeight: "450px", overflowY: "auto", padding: "10px", paddingRight: "20px",
                  fontSize: isMobile ? "10px" : ""
                }}>
                  {renderFolderStructure(folderStructure)}
                </div>
              </div>
              <div style={{ flex: 1, border: "1px solid #50011a", borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px", marginRight: "5px", overflow: "hidden" }}>
                <h5 style={{
                  backgroundColor: "#50011a", color: "white", padding: "10px",
                  marginBlockEnd: "0px", fontSize: isMobile ? "14px" : "", whiteSpace: "nowrap",
                  overflowX: "auto"
                }}>
                  Text Representation
                </h5>
                <div style={{ maxHeight: "450px", overflowY: "auto", padding: "10px" }}>
                  <pre style={{ fontSize: isMobile ? "10px" : "" }}>{generateTextRepresentation(folderStructure)}</pre>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>Error fetching folder structure</div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default ViewFolderStructureModal;
