import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Dropdown } from "react-bootstrap";
import { getFormById, getAllFields, getFieldById, addForm, updateForm } from "../apis/formsApis";
import {
  FaAngleDown, FaPenToSquare, FaRegSquarePlus, FaPen, FaCheck, FaXmark, FaPlus, FaUpDown,
  FaAngleUp, FaTrash
} from "react-icons/fa6";
import "../styles/FormViewStyles.css";
import ThemeButton from "../components/themeButton";

const EditFormModal = ({ show, onHide, formID, setViewFormModal }) => {
  const [form, setForm] = useState(null);
  const [isTemplate, setIsTemplate] = useState(false);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const [editFormName, setEditFormName] = useState(false);
  const [currentFormName, setCurrentFormName] = useState("");
  const [editFormTitle, setEditFormTitle] = useState(false);
  const [currentFormTitle, setCurrentFormTitle] = useState("");
  const [editFormDescription, setEditFormDescription] = useState(false);
  const [currentFormDescription, setCurrentFormDescription] = useState("");
  const [templateFields, setTemplateFields] = useState(null);
  const [currentQuestions, setCurrentQuestions] = useState([]);
  const [isReorderingQuestions, setIsReorderingQuestions] = useState(false);
  const [editingQuestionTitle, setEditingQuestionTitle] = useState([]);
  const [editingQuestionType, setEditingQuestionType] = useState([]);
  const [editingQuestionRequired, setEditingQuestionRequired] = useState([]);
  const [editingQuestionChoices, setEditingQuestionChoices] = useState([]);
  const [editingQuestionFileType, setEditingQuestionFileType] = useState([]);
  const [editingQuestionScaleRange, setEditingQuestionScaleRange] = useState([]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        setProfile(userFromStorage);
        setViewFormModal(200);
        window.addEventListener("resize", handleResize);
      } else {
        setViewFormModal(401);
        onHide();
      }
    }
  }, [show, onHide, setViewFormModal]);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 750);
  };

  useEffect(() => {
    if (show && profile) {
      fetchForm();
    }
  }, [show, profile]);

  const getQuestionTypeText = (questionType) => {
    switch (questionType) {
      case "shortAnswer":
        return "Short Answer";
      case "paragraph":
        return "Paragraph";
      case "checkboxes":
        return "Checkboxes";
      case "multipleChoice":
        return "Multiple Choice";
      case "dropdown":
        return "Dropdown";
      case "date":
        return "Date";
      case "time":
        return "Time";
      case "fileUpload":
        return "File Upload";
      case "scale":
        return "Scale";
      case "numeric":
        return "Numeric";
      default:
        return "Unknown";
    }
  };

  const fetchForm = async () => {
    setIsLoading(true);
    const response = await getFormById(formID);

    if (response.status >= 200 && response.status < 300) {
      const formData = response.data[0];
      setForm(formData);
      setIsTemplate(formData.template);
      setCurrentFormName(formData.name);
      setCurrentFormTitle(formData.structure.title);
      setCurrentFormDescription(formData.structure.description);
      // JS only copies first level properties, nested ones are referenced (Objects and Arrays)
      const formQuestionsDeepCopy = JSON.parse(JSON.stringify(formData.structure.questions));
      setCurrentQuestions(formQuestionsDeepCopy);
      // console.log(form.structure.title);
    } else {
      console.log("Error: ", response);
      setViewFormModal(response.status);
      onHide();
    }

    const fieldResponse = await getAllFields();
    if (fieldResponse.status >= 200 && fieldResponse.status < 300) {
      setTemplateFields(fieldResponse.data);
    } else {
      console.log(" Error: ", fieldResponse);
      setViewFormModal(fieldResponse.status);
      onHide();
    }
    setIsLoading(false);
  };

  const handleEditFormNameMode = () => {
    setEditFormName(!editFormName);
  };

  const handleFormNameChange = (event) => {
    setCurrentFormName(event.target.value);
  };

  const handleSaveFormName = () => {
    setForm({ ...form, name: currentFormName });
    setEditFormName(false);
  };

  const handleCancelFormNameChange = () => {
    setEditFormName(false);
  };

  const handleEditFormTitleMode = () => {
    setEditFormTitle(!editFormTitle);
  };

  const handleFormTitleChange = (event) => {
    setCurrentFormTitle(event.target.value);
  };

  const handleSaveFormTitle = () => {
    const updatedForm = { ...form };
    updatedForm.structure.title = currentFormTitle;
    setForm(updatedForm);
    setEditFormTitle(false);
  };

  const handleCancelFormTitleChange = () => {
    setEditFormTitle(false);
  };

  const handleEditFormDescriptionMode = () => {
    setEditFormDescription(!editFormDescription);
    console.log(form);
  };

  const handleFormDescriptionChange = (event) => {
    setCurrentFormDescription(event.target.value);
  };

  const handleSaveFormDescription = () => {
    const updatedForm = { ...form };
    updatedForm.description = currentFormDescription;
    updatedForm.structure.description = currentFormDescription;
    setForm(updatedForm);
    setEditFormDescription(false);
  };

  const handleCancelFormDescriptionChange = () => {
    setEditFormDescription(false);
  };

  const handleReorderQuestionsMode = () => {
    setExpandedQuestions([]);
    setIsReorderingQuestions(!isReorderingQuestions);
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleMoveUpQuestion = (index) => {
    if (index > 0) {
      const updatedQuestions = [...currentQuestions];
      const temp = updatedQuestions[index];
      updatedQuestions[index] = updatedQuestions[index - 1];
      updatedQuestions[index - 1] = temp;
      setCurrentQuestions(updatedQuestions);
    }
  };

  const handleMoveDownQuestion = (index) => {
    if (index < currentQuestions.length - 1) {
      const updatedQuestions = [...currentQuestions];
      const temp = updatedQuestions[index];
      updatedQuestions[index] = updatedQuestions[index + 1];
      updatedQuestions[index + 1] = temp;
      setCurrentQuestions(updatedQuestions);
    }
  };

  const handleQuestionDelete = (index) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions.splice(index, 1);
    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmReorderQuestions = () => {
    const updatedForm = { ...form };
    updatedForm.structure.questions = currentQuestions;
    setForm(updatedForm);
    setIsReorderingQuestions(false);
  };

  const handleCancelReorderQuestions = () => {
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
    setIsReorderingQuestions(false);
  };

  const handleAddBlankQuestion = (type) => {
    const defaultField = {
      title: "New Question",
      type: type,
      isRequired: false,
      ...(type === "checkboxes" || type === "multipleChoice" || type === "scale" || type === "dropdown" ? { choices: [] } : {}),
      ...(type === "fileUpload" ? { fileTypes: [] } : {}),
    };

    const updatedForm = { ...form };
    updatedForm.structure.questions = [...updatedForm.structure.questions, defaultField];
    setForm(updatedForm);
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
    // console.log("Current Questions: ", currentQuestions);
    // console.log("Form: ", form);
  };

  const handleAddTemplateQuestion = async (fieldID) => {
    const field = await getFieldById(fieldID);
    if (field.status >= 200 && field.status < 300) {
      const updatedForm = { ...form };
      updatedForm.structure.questions = [...updatedForm.structure.questions, field.data[0].structure];
      setForm(updatedForm);
      const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
      setCurrentQuestions(formQuestionsDeepCopy);
    } else {
      setViewFormModal(field.status);
      onHide();
      console.log("Error: ", field);
    }
  };

  const handleQuestionTitleChange = (e, index) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[index].title = e.target.value;
    setCurrentQuestions(updatedQuestions);
  };

  const handleQuestionEditTitleMode = (index) => {
    setEditingQuestionTitle([...editingQuestionTitle, index]);
  };

  const handleConfirmQuestionTitleChange = (index) => {
    const updatedForm = { ...form };
    updatedForm.structure.questions[index].title = currentQuestions[index].title;
    setForm(updatedForm);
    setEditingQuestionTitle(editingQuestionTitle.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleCancelQuestionTitleChange = (index) => {
    setEditingQuestionTitle(editingQuestionTitle.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleQuestionRequiredChangeMode = (index) => {
    setEditingQuestionRequired([...editingQuestionRequired, index]);
  };

  const handleQuestionRequiredChange = (isRequired, index) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[index].isRequired = isRequired;
    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmQuestionRequiredChange = (index) => {
    const updatedForm = { ...form };
    updatedForm.structure.questions[index].isRequired = currentQuestions[index].isRequired;
    setForm(updatedForm);
    setEditingQuestionRequired(editingQuestionRequired.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleCancelQuestionRequiredChange = (index) => {
    setEditingQuestionRequired(editingQuestionRequired.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleQuestionTypeEditMode = (index) => {
    setEditingQuestionType([...editingQuestionType, index]);
  };

  const handleQuestionTypeChange = (type, index) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[index].type = type;
    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmQuestionTypeChange = (index) => {
    const updatedForm = { ...form };
    updatedForm.structure.questions[index].type = currentQuestions[index].type;

    // Remove choices or fileTypes based on the new question type
    if (currentQuestions[index].type === "paragraph" || currentQuestions[index].type === "shortAnswer") {
      delete updatedForm.structure.questions[index].choices;
      delete updatedForm.structure.questions[index].fileTypes;
      setEditingQuestionChoices(editingQuestionChoices.filter((i) => i !== index));
      setEditingQuestionFileType(editingQuestionFileType.filter((i) => i !== index));
    } else if (currentQuestions[index].type === "checkboxes" || currentQuestions[index].type === "multipleChoice" ||
      currentQuestions[index].type === "scale" || currentQuestions[index].type === "dropdown") {
      updatedForm.structure.questions[index].choices = [];
      setEditingQuestionFileType(editingQuestionFileType.filter((i) => i !== index));
    } else if (currentQuestions[index].type === "fileUpload") {
      updatedForm.structure.questions[index].fileTypes = [];
      setEditingQuestionChoices(editingQuestionChoices.filter((i) => i !== index));
    }

    setForm(updatedForm);
    // console.log("Form: ", form);
    setEditingQuestionType(editingQuestionType.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleCancelQuestionTypeChange = (index) => {
    setEditingQuestionType(editingQuestionType.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleQuestionEditChoiceMode = (index) => {
    setEditingQuestionChoices([...editingQuestionChoices, index]);
  };

  const handleQuestionChoiceTextChange = (e, questionIndex, choiceIndex) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[questionIndex].choices[choiceIndex] = e.target.value;
    setCurrentQuestions(updatedQuestions);
  };

  const handleQuestionChoiceMoveUp = (questionIndex, choiceIndex) => {
    if (choiceIndex > 0) {
      const updatedQuestions = [...currentQuestions];
      const temp = updatedQuestions[questionIndex].choices[choiceIndex - 1];
      updatedQuestions[questionIndex].choices[choiceIndex - 1] = updatedQuestions[questionIndex].choices[choiceIndex];
      updatedQuestions[questionIndex].choices[choiceIndex] = temp;
      setCurrentQuestions(updatedQuestions);
    }
  };

  const handleQuestionChoiceMoveDown = (questionIndex, choiceIndex) => {
    if (choiceIndex < currentQuestions[questionIndex].choices.length - 1) {
      const updatedQuestions = [...currentQuestions];
      const temp = updatedQuestions[questionIndex].choices[choiceIndex + 1];
      updatedQuestions[questionIndex].choices[choiceIndex + 1] = updatedQuestions[questionIndex].choices[choiceIndex];
      updatedQuestions[questionIndex].choices[choiceIndex] = temp;
      setCurrentQuestions(updatedQuestions);
    }
  };

  const handleQuestionChoiceDelete = (questionIndex, choiceIndex) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[questionIndex].choices.splice(choiceIndex, 1);
    setCurrentQuestions(updatedQuestions);
  };

  const handleQuestionChoiceAdd = (questionIndex) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[questionIndex].choices.push("");
    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmQuestionEditChoice = (index) => {
    const updatedForm = { ...form };
    updatedForm.structure.questions[index].choices = currentQuestions[index].choices;
    setForm(updatedForm);
    setEditingQuestionChoices(editingQuestionChoices.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleCancelQuestionEditChoice = (index) => {
    setEditingQuestionChoices(editingQuestionChoices.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleQuestionFileTypeEditMode = (index) => {
    setEditingQuestionFileType([...editingQuestionFileType, index]);
  };

  const handleQuestionFileTypeTextChange = (e, questionIndex, fileTypeIndex) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[questionIndex].fileTypes[fileTypeIndex] = e.target.value;
    setCurrentQuestions(updatedQuestions);
  };

  const handleQuestionFileTypeDelete = (questionIndex, fileTypeIndex) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[questionIndex].fileTypes.splice(fileTypeIndex, 1);
    setCurrentQuestions(updatedQuestions);
  };

  const handleQuestionFileTypeAdd = (questionIndex) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[questionIndex].fileTypes.push("");
    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmQuestionFileTypeEdit = (index) => {
    const invalidFileTypes = currentQuestions[index].fileTypes.filter(fileType => !isValidFileType(fileType));
    if (invalidFileTypes.length > 0) {
      alert(`Invalid file types: ${invalidFileTypes.join(", ")}`);
      return;
    }

    const updatedForm = { ...form };
    updatedForm.structure.questions[index].fileTypes = currentQuestions[index].fileTypes;
    setForm(updatedForm);
    setEditingQuestionFileType(editingQuestionFileType.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);

  };

  const handleCancelQuestionFileTypeEdit = (index) => {
    setEditingQuestionFileType(editingQuestionFileType.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleQuestionScaleRangeEditMode = (index) => {
    setEditingQuestionScaleRange([...editingQuestionScaleRange, index]);
  };

  const handleMinNumberChange = (event, index) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[index].minNumber = event;
    setCurrentQuestions(updatedQuestions);
  };

  const handleMaxNumberChange = (event, index) => {
    const updatedQuestions = [...currentQuestions];
    updatedQuestions[index].maxNumber = event;
    setCurrentQuestions(updatedQuestions);
  };

  const handleConfirmQuestionScaleRangeEdit = (index) => {
    const updatedForm = { ...form };
    updatedForm.structure.questions[index].maxNumber = currentQuestions[index].maxNumber;
    updatedForm.structure.questions[index].minNumber = currentQuestions[index].minNumber;
    setForm(updatedForm);
    setEditingQuestionScaleRange(editingQuestionScaleRange.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const handleCancelQuestionScaleRangeEdit = (index) => {
    setEditingQuestionScaleRange(editingQuestionScaleRange.filter((i) => i !== index));
    const formQuestionsDeepCopy = JSON.parse(JSON.stringify(form.structure.questions));
    setCurrentQuestions(formQuestionsDeepCopy);
  };

  const isValidFileType = (fileType) => {
    const validExtensions = [
      ".pdf", ".doc", ".docx", ".xls", ".xlsx", ".ppt", ".pptx", ".jpg", ".jpeg", ".png", ".gif",
      ".bmp", ".tiff", ".mp4", ".avi", ".mov", ".mkv", ".zip", ".rar", ".7z", ".tar", ".gz"
    ];
    return validExtensions.includes(fileType.toLowerCase());
  };

  const resetState = () => {
    setExpandedQuestions([]);
    setIsReorderingQuestions(false);
    setForm(null);
    setProfile(null);
    setIsLoading(false);
  };

  const handleAddForm = async () => {
    form.name = form.name + " Copy";
    const response = await addForm(form, isTemplate);
    switch (response.status) {
      case 200:
        alert("Form added successfully!");
        setViewFormModal(200);
        resetState();
        onHide();
        break;
      case 400:
        alert("Error: Bad Request, Details:" + response.data);
        setViewFormModal(400);
        resetState();
        onHide();
        break;
      case 401:
        alert("Error: Unauthorized, Details:" + response.data);
        setViewFormModal(401);
        resetState();
        onHide();
        break;
      case 403:
        alert("Error: Forbidden, Details:" + response.data);
        setViewFormModal(403);
        resetState();
        onHide();
        break;
      case 500:
        alert("Error: Internal Server Error, Details:" + response.data);
        setViewFormModal(500);
        resetState();
        onHide();
        break;
      default:
        alert("Error: " + response.status + ", Details:" + response.data);
        setViewFormModal(response.status);
        resetState();
        onHide();
        break;
    }
  };

  const handleUpdateForm = async () => {
    const response = await updateForm(formID, form);
    switch (response.status) {
      case 200:
        alert("Form updated successfully!");
        setViewFormModal(200);
        resetState();
        onHide();
        break;
      case 400:
        alert("Error: Bad Request, Details:" + response.data);
        setViewFormModal(400);
        resetState();
        onHide();
        break;
      case 401:
        alert("Error: Unauthorized, Details:" + response.data);
        setViewFormModal(401);
        resetState();
        onHide();
        break;
      case 403:
        alert("Error: Forbidden, Details:" + response.data);
        setViewFormModal(403);
        resetState();
        onHide();
        break;
      case 500:
        alert("Error: Internal Server Error, Details:" + response.data);
        setViewFormModal(500);
        resetState();
        onHide();
        break;
      default:
        alert("Error: " + response.status + ", Details:" + response.data);
        setViewFormModal(response.status);
        resetState();
        onHide();
        break;
    }
  };

  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} centered dialogClassName="modal-dialog-centered" size="lg">
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          View Form
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ maxHeight: "650px", overflowY: "auto", maxWidth: "800px", overflowX: "auto" }}>
        {isLoading ? (
          <div className="text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : form ? (
          <div>
            <div className="form-attribute-container">
              {editFormName ? (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <strong style={{ fontSize: isMobile ? "14px" : "20px", textWrap: "nowrap" }}>From Name:</strong>{" "}
                  <Form.Control style={{ fontSize: isMobile ? "14px" : "" }} type="text" value={currentFormName} onChange={handleFormNameChange} />
                  <>
                    <Button variant="outline-success"
                      style={{
                        marginLeft: "10px",
                        padding: "2px 8px",
                        fontSize: "14px",
                      }} onClick={handleSaveFormName}>
                      <FaCheck />
                    </Button>
                    <Button variant="outline-danger"
                      style={{
                        marginLeft: "5px",
                        padding: "2px 8px",
                        fontSize: "14px",
                      }} onClick={handleCancelFormNameChange}>
                      <FaXmark />
                    </Button>
                  </>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <strong style={{ fontSize: isMobile ? "14px" : "20px", textWrap: "nowrap" }}>From Name:</strong>{" "}
                  <span style={{ fontSize: isMobile ? "14px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }}>
                    {form.name}
                  </span>
                  <ThemeButton variant="outline-primary" style={{
                    marginLeft: "10px",
                    padding: "2px 8px",
                    fontSize: isMobile ? "12px" : "14px",
                  }} onClick={handleEditFormNameMode}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaPen />{isMobile ? null : "Edit"}
                    </div>
                  </ThemeButton>
                </div>
              )}
            </div>
            <div className="form-attribute-container">
              {editFormTitle ? (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <strong style={{ fontSize: isMobile ? "14px" : "20px", textWrap: "nowrap" }}>Title:</strong>{" "}
                  <Form.Control style={{ fontSize: isMobile ? "14px" : "" }} type="text" value={currentFormTitle} onChange={handleFormTitleChange} />
                  <>
                    <Button variant="outline-success"
                      style={{
                        marginLeft: "10px",
                        padding: "2px 8px",
                        fontSize: "14px",
                      }} onClick={handleSaveFormTitle}>
                      <FaCheck />
                    </Button>
                    <Button variant="outline-danger"
                      style={{
                        marginLeft: "5px",
                        padding: "2px 8px",
                        fontSize: "14px",
                      }} onClick={handleCancelFormTitleChange}>
                      <FaXmark />
                    </Button>
                  </>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Title:</strong>{" "}
                  <span style={{ fontSize: isMobile ? "14px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }}>
                    {form.structure.title}
                  </span>
                  <ThemeButton variant="outline-primary" style={{
                    marginLeft: "10px",
                    padding: "2px 8px",
                    fontSize: isMobile ? "12px" : "14px",
                  }} onClick={handleEditFormTitleMode}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaPen /> {isMobile ? null : "Edit"}
                    </div>
                  </ThemeButton>
                </div>
              )}
            </div>
            <div className="form-attribute-container">
              {editFormDescription ? (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Description:</strong>{" "}
                  <Form.Control style={{ fontSize: isMobile ? "14px" : "" }} type="text" value={currentFormDescription} onChange={handleFormDescriptionChange} />
                  <>
                    <Button variant="outline-success"
                      style={{
                        marginLeft: "10px",
                        padding: "2px 8px",
                        fontSize: "14px",
                      }} onClick={handleSaveFormDescription}>
                      <FaCheck />
                    </Button>
                    <Button variant="outline-danger"
                      style={{
                        marginLeft: "5px",
                        padding: "2px 8px",
                        fontSize: "14px",
                      }} onClick={handleCancelFormDescriptionChange}>
                      <FaXmark />
                    </Button>
                  </>
                </div>
              ) : (
                <div style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                  <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Description:</strong>{" "}
                  <span style={{ fontSize: isMobile ? "14px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }}>
                    {form.structure.description}
                  </span>
                  <ThemeButton variant="outline-primary" style={{
                    marginLeft: "10px",
                    padding: "2px 8px",
                    fontSize: isMobile ? "12px" : "14px",
                  }} onClick={handleEditFormDescriptionMode}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaPen /> {isMobile ? null : "Edit"}
                    </div>
                  </ThemeButton>
                </div>
              )}
            </div>
            <div className="questionContainer">
              <strong style={{ fontSize: isMobile ? "14px" : "20px" }}>Questions:</strong>
              <div style={{ display: "flex", alignItems: "center", gap: "5px", flexDirection: isMobile ? "column" : "row" }}>
                <Dropdown >
                  <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic"
                    style={{
                      "--bs-btn-color": "#50011a",
                      "--bs-btn-border-color": "#50011a",
                      "--bs-btn-hover-color": "#fff",
                      "--bs-btn-hover-bg": "#50011a",
                      "--bs-btn-hover-border-color": "#50011a",
                      "--bs-btn-focus-shadow-rgb": "33,37,41",
                      "--bs-btn-active-color": "#fff",
                      "--bs-btn-active-bg": "#50011a",
                      "--bs-btn-active-border-color": "#50011a",
                      marginLeft: "10px",
                      padding: "2px 8px",
                      fontSize: isMobile ? "12px" : "14px",
                    }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaPlus />
                      Add Question
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ fontSize: isMobile ? "12px" : "" }}>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("shortAnswer"); }}>Short Answer</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("paragraph"); }}>Paragraph</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("checkboxes"); }}>Checkboxes</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("multipleChoice"); }}>Multiple Choice</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("dropdown"); }}>Dropdown</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("date"); }}>Date</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("time"); }}>Time</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("fileUpload"); }}>File Upload</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("scale"); }}>Scale</Dropdown.Item>
                    <Dropdown.Item onClick={() => { handleAddBlankQuestion("numeric"); }}>Numeric</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown >
                  <Dropdown.Toggle
                    variant="outline-primary"
                    id="dropdown-basic"
                    style={{
                      "--bs-btn-color": "#50011a",
                      "--bs-btn-border-color": "#50011a",
                      "--bs-btn-hover-color": "#fff",
                      "--bs-btn-hover-bg": "#50011a",
                      "--bs-btn-hover-border-color": "#50011a",
                      "--bs-btn-focus-shadow-rgb": "33,37,41",
                      "--bs-btn-active-color": "#fff",
                      "--bs-btn-active-bg": "#50011a",
                      "--bs-btn-active-border-color": "#50011a",
                      marginLeft: "10px",
                      padding: "2px 8px",
                      fontSize: isMobile ? "12px" : "14px",
                    }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaPlus />
                      Add a Preset Question
                    </div>
                  </Dropdown.Toggle>
                  <Dropdown.Menu style={{ fontSize: isMobile ? "12px" : "" }}>
                    {templateFields.map((field) => (
                      <Dropdown.Item key={field.id} onClick={() => { handleAddTemplateQuestion(field.id); }}>
                        {field.title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <div>
                  <ThemeButton variant="outline-primary" style={{
                    marginLeft: "10px",
                    padding: "2px 8px",
                    fontSize: isMobile ? "12px" : "14px",
                  }} onClick={handleReorderQuestionsMode}>
                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                      <FaUpDown />
                      Reorder Questions
                    </div>
                  </ThemeButton>
                  {isReorderingQuestions && (
                    <>
                      <ThemeButton variant="outline-primary" style={{ marginLeft: "10px", }}
                        onClick={handleConfirmReorderQuestions}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <FaCheck />
                        </div>
                      </ThemeButton>
                      <ThemeButton variant="outline-primary" style={{ marginLeft: "10px", }}
                        onClick={handleCancelReorderQuestions}>
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <FaXmark />
                        </div>
                      </ThemeButton>
                    </>
                  )}
                </div>
              </div>
              <div>
                {currentQuestions.map((question, index) => (
                  <div key={index} className="questions-container">
                    <div className="question-header" role="button" tabIndex="0"
                      onClick={() => {
                        if (!isReorderingQuestions) {
                          setExpandedQuestions((prevState) =>
                            prevState.includes(index) ? prevState.filter((i) => i !== index) : [...prevState, index]
                          );
                        }
                      }}
                      onKeyDown={(event) => {
                        if (!isReorderingQuestions && event.key === "Enter") {
                          setExpandedQuestions((prevState) =>
                            prevState.includes(index) ? prevState.filter((i) => i !== index) : [...prevState, index]
                          );
                        }
                      }}>
                      <strong style={{ fontSize: isMobile ? "14px" : "18px" }}>Question {index + 1}:</strong>{" "}
                      <span style={{ fontSize: isMobile ? "14px" : "18px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }}>
                        {question.title}
                      </span>
                      <div>
                        {isReorderingQuestions ? (
                          <div>
                            <ThemeButton variant="outline-primary" style={{
                              marginLeft: "10px", padding: "2px 8px", fontSize: isMobile ? "12px" : "14px",
                            }} onClick={() => handleMoveUpQuestion(index)} disabled={index === 0}>
                              <FaAngleUp />
                            </ThemeButton>
                            <ThemeButton variant="outline-primary" style={{
                              marginLeft: "10px", padding: "2px 8px", fontSize: isMobile ? "12px" : "14px",
                            }} onClick={() => handleMoveDownQuestion(index)} disabled={index === form.structure.questions.length - 1}>
                              <FaAngleDown />
                            </ThemeButton>
                            <ThemeButton
                              variant="outline-danger" style={{
                                marginLeft: "10px",
                                padding: "2px 8px",
                                fontSize: isMobile ? "12px" : "14px",
                              }} onClick={() => handleQuestionDelete(index)}>
                              <FaTrash />
                            </ThemeButton>
                          </div>
                        ) : (
                          <FaAngleDown className={`expanded-icon ${expandedQuestions.includes(index) ? "expanded" : ""}`} />
                        )}
                      </div>
                    </div>
                    {expandedQuestions.includes(index) && (
                      <>
                        <div className="field-attribute-container-in-form">
                          <strong style={{ fontSize: isMobile ? "12px" : "16px", textWrap: "nowrap" }}>Question Title:</strong>{" "}
                          {editingQuestionTitle.includes(index) ? (
                            <Form.Control
                              type="text"
                              value={currentQuestions[index].title}
                              onChange={(event) => handleQuestionTitleChange(event, index)}
                            />
                          ) : (
                            <span style={{ fontSize: isMobile ? "12px" : "14px", maxWidth: "100%", paddingLeft: "10px", textWrap: "nowrap", overflowX: "auto" }} title={question.title}>
                              {question.title}
                            </span>
                          )}
                          {editingQuestionTitle.includes(index) ? (
                            <>
                              <Button variant="outline-success"
                                style={{
                                  marginLeft: "10px",
                                  padding: "2px 8px",
                                  fontSize: "14px",
                                }} onClick={() => handleConfirmQuestionTitleChange(index)}>
                                <FaCheck />
                              </Button>
                              <Button variant="outline-danger"
                                style={{
                                  marginLeft: "5px",
                                  padding: "2px 8px",
                                  fontSize: "14px",
                                }} onClick={() => handleCancelQuestionTitleChange(index)}>
                                <FaXmark />
                              </Button>
                            </>
                          ) : (
                            <ThemeButton variant="outline-primary" style={{
                              marginLeft: "10px",
                              padding: "2px 8px",
                              fontSize: isMobile ? "12px" : "14px",
                            }} onClick={() => handleQuestionEditTitleMode(index)}>
                              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <FaPen /> {isMobile ? "" : "Edit"}
                              </div>
                            </ThemeButton>
                          )}
                        </div>
                        <div className="field-attribute-container-in-form">
                          <strong style={{ fontSize: isMobile ? "12px" : "16px" }}>Question Type:</strong>{" "}
                          {editingQuestionType.includes(index) ? (
                            <>
                              <Dropdown >
                                <Dropdown.Toggle
                                  variant="outline-primary"
                                  id="dropdown-basic"
                                  style={{
                                    "--bs-btn-color": "#50011a",
                                    "--bs-btn-border-color": "#50011a",
                                    "--bs-btn-hover-color": "#fff",
                                    "--bs-btn-hover-bg": "#50011a",
                                    "--bs-btn-hover-border-color": "#50011a",
                                    "--bs-btn-focus-shadow-rgb": "33,37,41",
                                    "--bs-btn-active-color": "#fff",
                                    "--bs-btn-active-bg": "#50011a",
                                    "--bs-btn-active-border-color": "#50011a",
                                    marginLeft: "10px",
                                    padding: "2px 8px",
                                    fontSize: isMobile ? "12px" : "14px",
                                  }}>
                                  {getQuestionTypeText(question.type)}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => handleQuestionTypeChange("shortAnswer", index)}>Short Answer</Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleQuestionTypeChange("paragraph", index)}>Paragraph</Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleQuestionTypeChange("checkboxes", index)}>Checkboxes</Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleQuestionTypeChange("multipleChoice", index)}>Multiple Choice</Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleQuestionTypeChange("dropdown", index)}>Dropdown</Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleQuestionTypeChange("date", index)}>Date</Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleQuestionTypeChange("time", index)}>Time</Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleQuestionTypeChange("fileUpload", index)}>File Upload</Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleQuestionTypeChange("scale", index)}>Scale</Dropdown.Item>
                                  <Dropdown.Item onClick={() => handleQuestionTypeChange("numeric", index)}>Numeric</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                              <Button variant="outline-success" style={{
                                marginLeft: "10px",
                                padding: "2px 8px",
                                fontSize: "14px",
                              }} onClick={() => handleConfirmQuestionTypeChange(index)}>
                                <FaCheck />
                              </Button>
                              <Button variant="outline-danger" style={{
                                marginLeft: "5px",
                                padding: "2px 8px",
                                fontSize: "14px",
                              }} onClick={() => handleCancelQuestionTypeChange(index)}>
                                <FaXmark />
                              </Button>
                            </>
                          ) : (
                            <>
                              <span style={{ fontSize: isMobile ? "12px" : "14px", paddingLeft: "10px" }} title={getQuestionTypeText(question.type)}>
                                {getQuestionTypeText(question.type)}
                              </span>
                              <ThemeButton variant="outline-primary" style={{
                                marginLeft: "10px",
                                padding: "2px 8px",
                                fontSize: isMobile ? "12px" : "14px",
                              }} onClick={() => handleQuestionTypeEditMode(index)}>
                                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                  <FaPen />{isMobile ? "" : "Edit"}
                                </div>
                              </ThemeButton>
                            </>
                          )}
                        </div>
                        <div className="field-attribute-container-in-form">
                          <strong style={{ fontSize: isMobile ? "12px" : "16px" }}>Question Required:</strong>{" "}
                          {editingQuestionRequired.includes(index) ? (
                            <Dropdown>
                              <Dropdown.Toggle
                                variant="outline-primary"
                                id="dropdown-basic"
                                style={{
                                  "--bs-btn-color": "#50011a",
                                  "--bs-btn-border-color": "#50011a",
                                  "--bs-btn-hover-color": "#fff",
                                  "--bs-btn-hover-bg": "#50011a",
                                  "--bs-btn-hover-border-color": "#50011a",
                                  "--bs-btn-focus-shadow-rgb": "33,37,41",
                                  "--bs-btn-active-color": "#fff",
                                  "--bs-btn-active-bg": "#50011a",
                                  "--bs-btn-active-border-color": "#50011a",
                                  marginLeft: "10px",
                                  padding: "2px 8px",
                                  fontSize: "14px",
                                }}>
                                {question.isRequired ? "Yes" : "No"}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleQuestionRequiredChange(true, index)}>
                                  Yes
                                </Dropdown.Item>
                                <Dropdown.Item onClick={() => handleQuestionRequiredChange(false, index)}>
                                  No
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          ) : (
                            <span style={{ fontSize: isMobile ? "12px" : "14px", paddingLeft: "10px" }}>
                              {question.isRequired ? "Yes" : "No"}
                            </span>
                          )}
                          {editingQuestionRequired.includes(index) ? (
                            <>
                              <Button variant="outline-success"
                                style={{
                                  marginLeft: "10px",
                                  padding: "2px 8px",
                                  fontSize: "14px",
                                }} onClick={() => handleConfirmQuestionRequiredChange(index)}>
                                <FaCheck />
                              </Button>
                              <Button variant="outline-danger"
                                style={{
                                  marginLeft: "5px",
                                  padding: "2px 8px",
                                  fontSize: "14px",
                                }} onClick={() => handleCancelQuestionRequiredChange(index)}>
                                <FaXmark />
                              </Button>
                            </>
                          ) : (
                            <ThemeButton variant="outline-primary" style={{
                              marginLeft: "10px",
                              padding: "2px 8px",
                              fontSize: isMobile ? "12px" : "14px",
                            }} onClick={() => handleQuestionRequiredChangeMode(index)}>
                              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                <FaPen /> {isMobile ? "" : "Edit"}
                              </div>
                            </ThemeButton>
                          )}

                        </div>
                        {(question.type === "checkboxes" || question.type === "multipleChoice" || question.type === "dropdown")
                          && !editingQuestionType.includes(index) &&
                          (
                            <div className="field-attribute-container-in-form" style={{ display: "block" }}>
                              <strong style={{ fontSize: isMobile ? "12px" : "16px", marginBottom: "0" }}>Choices:</strong>
                              {editingQuestionChoices.includes(index) ? (
                                <>
                                  {question.choices.map((choice, choiceIndex) => (
                                    <div key={choiceIndex} className="field-list-item-container">
                                      <input type="text" value={choice} onChange={
                                        (e) => handleQuestionChoiceTextChange(e, index, choiceIndex)}
                                      />
                                      <ThemeButton variant="outline-primary" style={{
                                        marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                                      }} onClick={() => handleQuestionChoiceDelete(index, choiceIndex)}>
                                        <FaTrash />
                                      </ThemeButton>
                                      <ThemeButton variant="outline-primary" style={{
                                        marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                                      }} disabled={choiceIndex === 0} onClick={() => handleQuestionChoiceMoveUp(index, choiceIndex)}>
                                        <FaAngleUp />
                                      </ThemeButton>
                                      <ThemeButton variant="outline-primary" style={{
                                        marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                                      }} disabled={choiceIndex === question.choices.length - 1} onClick={() => handleQuestionChoiceMoveDown(index, choiceIndex)}>
                                        <FaAngleDown />
                                      </ThemeButton>
                                    </div>
                                  ))}
                                  <ThemeButton variant="outline-primary" style={{
                                    marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                                  }} onClick={() => handleQuestionChoiceAdd(index)}>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                      <FaPlus /> Add Choice
                                    </div>
                                  </ThemeButton>
                                  <ThemeButton variant="outline-primary" style={{
                                    marginLeft: "10px",
                                    padding: "2px 8px",
                                    fontSize: "14px"
                                  }} onClick={() => handleConfirmQuestionEditChoice(index)}>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                      <FaCheck /> Confirm
                                    </div>
                                  </ThemeButton>
                                  <ThemeButton variant="outline-primary" style={{
                                    marginLeft: "10px",
                                    padding: "2px 8px",
                                    fontSize: "14px"
                                  }} onClick={() => handleCancelQuestionEditChoice(index)}>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                      <FaXmark /> Cancel
                                    </div>
                                  </ThemeButton>
                                </>
                              ) : (
                                <>
                                  <ThemeButton variant="outline-primary" style={{
                                    marginLeft: "10px",
                                    padding: "2px 8px",
                                    fontSize: isMobile ? "12px" : "14px",
                                  }} onClick={() => handleQuestionEditChoiceMode(index)}>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                      <FaPen /> {isMobile ? "" : "Edit"}
                                    </div>
                                  </ThemeButton>
                                  <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                                    {(
                                      question.choices.map((choice, index) => (
                                        <div key={index} className="field-list-item-container">
                                          {" > "}
                                          <span style={{ fontSize: isMobile ? "10px" : "" }}>
                                            {choice}
                                          </span>
                                        </div>
                                      ))
                                    )}
                                  </ul>
                                </>
                              )}
                            </div>
                          )}
                        {question.type === "fileUpload" && !editingQuestionType.includes(index) && (
                          <div className="field-attribute-container-in-form" style={{ display: "block" }}>
                            <strong style={{ fontSize: isMobile ? "12px" : "16px", marginBottom: "0" }}>File Types Allowed:</strong>
                            {editingQuestionFileType.includes(index) ? (
                              <>
                                {question.fileTypes.map((fileType, fileTypeIndex) => (
                                  <div key={fileTypeIndex} className="field-list-item-container">
                                    <input type="text" value={fileType}
                                      onChange={(e) => handleQuestionFileTypeTextChange(e, index, fileTypeIndex)}
                                      style={{ width: "100px", fontSize: isMobile ? "12px" : "" }}
                                    />
                                    <ThemeButton
                                      variant="outline-primary" style={{
                                        marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                                      }} onClick={() => handleQuestionFileTypeDelete(index, fileTypeIndex)}>
                                      <FaTrash />
                                    </ThemeButton>
                                  </div>
                                ))}
                                <ThemeButton variant="outline-primary" style={{
                                  marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                                }} onClick={() => handleQuestionFileTypeAdd(index)}>
                                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <FaPlus /> Add File Type
                                  </div>
                                </ThemeButton>
                                <ThemeButton variant="outline-primary" style={{
                                  marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                                }} onClick={() => handleConfirmQuestionFileTypeEdit(index)}>
                                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <FaCheck /> Confirm
                                  </div>
                                </ThemeButton>
                                <ThemeButton variant="outline-primary" style={{
                                  marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                                }} onClick={() => handleCancelQuestionFileTypeEdit(index)}>
                                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <FaXmark /> Cancel
                                  </div>
                                </ThemeButton>
                              </>
                            ) : (
                              <>
                                <ThemeButton variant="outline-primary" style={{
                                  marginLeft: "10px", padding: "2px 8px", fontSize: isMobile ? "12px" : "14px",
                                }} onClick={() => handleQuestionFileTypeEditMode(index)}>
                                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <FaPen /> {isMobile ? "" : "Edit"}
                                  </div>
                                </ThemeButton>
                                <ul style={{ listStyleType: "none", paddingLeft: 0 }}>
                                  {question.fileTypes.map((fileType, index) => (
                                    <div key={index} className="field-list-item-container">
                                      {" > "}
                                      <span style={{ fontSize: isMobile ? "10px" : "" }}>
                                        {fileType}
                                      </span>
                                    </div>
                                  ))}
                                </ul>
                              </>
                            )}

                          </div>
                        )}
                        {question.type === "scale" && !editingQuestionType.includes(index) && (
                          <div className="field-attribute-container-in-form" style={{ display: "block" }}>
                            <strong style={{ fontSize: isMobile ? "12px" : "16px", marginBottom: "0" }}>Range: </strong>
                            {editingQuestionScaleRange.includes(index) ? (
                              <>
                                <ThemeButton variant="outline-primary" style={{
                                  marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                                }} onClick={() => handleConfirmQuestionScaleRangeEdit(index)}>
                                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <FaCheck /> Confirm
                                  </div>
                                </ThemeButton>
                                <ThemeButton variant="outline-primary" style={{
                                  marginLeft: "10px", padding: "2px 8px", fontSize: "14px"
                                }} onClick={() => handleCancelQuestionScaleRangeEdit(index)}>
                                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <FaXmark /> Cancel
                                  </div>
                                </ThemeButton>
                                <Form.Group className="mb-3">
                                  <Form.Label style={{ fontSize: isMobile ? "12px" : "" }}>Minimum:</Form.Label>
                                  <Form.Control
                                    type="number"
                                    value={question.minNumber}
                                    onChange={(e) => handleMinNumberChange(parseFloat(e.target.value), index)}
                                  />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                  <Form.Label style={{ fontSize: isMobile ? "12px" : "" }}>Maximum:</Form.Label>
                                  <Form.Control
                                    type="number"
                                    value={question.maxNumber}
                                    onChange={(e) => handleMaxNumberChange(parseFloat(e.target.value), index)}
                                  />
                                </Form.Group>
                              </>
                            ) : (
                              <>
                                <ThemeButton variant="outline-primary" style={{
                                  marginLeft: "10px", padding: "2px 8px", fontSize: isMobile ? "12px" : "14px",
                                }} onClick={() => handleQuestionScaleRangeEditMode(index)}>
                                  <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                    <FaPen />{isMobile ? "" : "Edit"}
                                  </div>
                                </ThemeButton>
                                <div className="field-attribute-container">
                                  <span style={{ fontSize: isMobile ? "10px" : "" }}><strong>Minimum:</strong> {question.minNumber}</span>
                                </div>
                                <div className="field-attribute-container">
                                  <span style={{ fontSize: isMobile ? "10px" : "" }}><strong>Maximum:</strong> {question.maxNumber}</span>
                                </div>
                              </>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <h3>No form found</h3>
          </div>
        )}
      </Modal.Body>
      {
        form && (
          <Modal.Footer>
            <ThemeButton variant="outline-primary" onClick={handleUpdateForm}>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FaPenToSquare />
                Update Field
              </div>
            </ThemeButton>
            <ThemeButton variant="outline-primary" onClick={handleAddForm}>
              <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                <FaRegSquarePlus />
                Save a Copy
              </div>
            </ThemeButton>
          </Modal.Footer>
        )
      }

    </Modal >
  );
};

export default EditFormModal;