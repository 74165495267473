import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ThemeNavbar from "../components/themeNavbar.jsx";
import backgroundPattern from "../assets/images/background4.png";

function HomePage() {
  const [profile, setProfile] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);
  const navigate = useNavigate();

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setProfile(JSON.parse(storedUser));
      window.addEventListener("resize", handleResize);
    } else {
      navigate("/"); // Redirect to the unauthorized page if no user is found
    }
  }, [navigate]);

  const handleResize = () => {
    if (window.innerWidth < 750) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  return (
    <div style={{
      backgroundImage: `url(${backgroundPattern})`,
      backgroundRepeat: "repeat",
      backgroundSize: "150px 150px",
      minHeight: "100vh"
    }}>
      <ThemeNavbar />
      {profile && (
        <>
          <div className="container py-4 bg-white rounded shadow-sm">
            <h2 className="display-4 mb-4">Datascribe Studio</h2>
            <p>
              This webapp is intended to aid in the creation of folder structures in Google Drive, as well as provide a framework for creating, sharing, and filling out forms with pertinent data.
            </p>
            <h4 className="mb-4" style={{ fontSize: "1.5rem" }}>Datascribe Information</h4>
            <p>
              At Datascribe.cloud, <b>Our vision</b> is to support researchers and small businesses in unlocking the full potential of their data through accessible and affordable cloud-based solutions. We strive to be the go-to platform for innovative data management and analytics, empowering our users to drive impactful discoveries and informed decisions. By focusing on simplicity, reliability, and exceptional support, we aim to foster a collaborative environment that accelerates research and growth.
            </p>
            <p>
              At Datascribe.cloud, <b>Our mission</b> is to accelerate research with innovative, scalable, and secure cloud-based data solutions. We aim to simplify data management, enhance analytics capabilities, and drive informed decision-making by providing intuitive, reliable, and cost-effective services tailored to meet the unique needs of reseachers.
            </p>
            <h4 className="mb-4" style={{ fontSize: "1.5rem" }}>Helpful Links/Resources</h4>
            {profile && (
              <>
                <p>
                  For help/faqs, refer to the{" "}
                  <a
                    href="https://docs.google.com/document/d/1dog4_ZCKJAA9IQ5uyxEEd7Xo3S0Ta--1Nb52ZgDKl3U/edit?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#500000", textDecoration: "underline" }} // Aggie Maroon with underline
                    className="text-decoration-underline"
                  >
                    user documentation
                  </a>
                </p>
              </>
            )}
          </div>
          <div className="container d-flex justify-content-between mt-4" style={{ flexDirection: isMobile ? "column" : "row" }}>
            <div className="container" style={{ backgroundColor: "white", width: isMobile ? "100%" : "40vw", border: "1px solid #000", padding: "20px", marginBottom: "20px" }}>
              <h2 className="text-center">Folders</h2>
              <p className="text-center">
                <i className="fas fa-folder-open fa-3x"></i>
              </p>
              <p>
                Organize and manage your documents with ease. Our folder structure system helps you keep your files in order and accessible.
              </p>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <span>Folder 1</span>
                  <span className="badge bg-primary rounded-pill">14</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <span>Folder 2</span>
                  <span className="badge bg-primary rounded-pill">8</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <span>Folder 3</span>
                  <span className="badge bg-primary rounded-pill">5</span>
                </li>
              </ul>
            </div>
            <div className="container" style={{ backgroundColor: "white", width: isMobile ? "100%" : "40vw", border: "1px solid #000", padding: "20px", marginBottom: "20px" }}>
              <h2 className="text-center">Forms</h2>
              <p className="text-center">
                <i className="fas fa-file-alt fa-3x"></i>
              </p>
              <p>
                Create, share, and collect data seamlessly. Our form management system ensures you gather the information you need efficiently.
              </p>
              <ul className="list-group">
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <span>Form 1</span>
                  <span className="badge bg-success rounded-pill">20</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <span>Form 2</span>
                  <span className="badge bg-success rounded-pill">15</span>
                </li>
                <li className="list-group-item d-flex justify-content-between align-items-center">
                  <span>Form 3</span>
                  <span className="badge bg-success rounded-pill">10</span>
                </li>
              </ul>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default HomePage;
