import React, { useState, useEffect } from "react";
import Logout from "./logout";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import Avatar from "react-avatar";
import { useNavigate } from "react-router-dom";
import { FaBars, FaBarsStaggered } from "react-icons/fa6";

const StyledNavbar = styled(Navbar)`
  background-color: #50011a;
  color: white;
  height: 95px;
  margin: 5px 5px 5px 5px;
  border-radius: 7px;
  font-size: 20px;
  
  .navbar-brand {
    margin-left: 25px;
    padding: 10px;
    color: white;
  }

  .nav-link {
    color: white;
    transition: all 0.3s ease-in-out;
    position: relative;

    &:hover,
    &.active {
      color: #fff;
      text-decoration: none;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #fff;
        transform: scaleX(1);
        transition: transform 0.3s ease-in-out;
      }
    }

    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #fff;
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out;
    }
  }

  .navbar-toggler {
    color: white;
    border: none;
    transition: all 0.3s ease-in-out;
  }
  
  .navbar-toggler.open {
    transform: rotate(90deg);
  }

  @media (max-width: 1250px) {
    height: auto;
  
  .dropdown-menu {
    position: absolute;
    right: 0;
    left: auto;
    top: auto;
    z-index: 1000;
  }

  .dropdown-item {
    &:hover, &:focus {
      background-color: #6b0024;
    }
  }

    .navbar-collapse {
      background-color: #50011a;
      padding: 10px;
    }

    .nav-link {
      padding: 10px 0;
    }

    .navbar-nav {
      align-items: start;
    }
  }
`;

function ThemeNavbar() {
  const [isOpen, setIsOpen] = useState(false);
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1250);
  const navigate = useNavigate();

  const toggle = () => setIsOpen(!isOpen);
  const toggleNavbar = () => setIsNavbarExpanded(!isNavbarExpanded);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1250);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let userData = JSON.parse(localStorage.getItem("user"));
  if (!userData) {
    userData = { name: "Guest", picture: "" };
  }

  const goToProfile = () => {
    navigate("/profile");
  };

  return (
    <StyledNavbar expand={isMobile ? false : "lg"}>
      <Navbar.Brand href="/home" style={{ fontSize: "25px" }}>Datascribe.Cloud</Navbar.Brand>
      <Navbar.Toggle style={{ marginRight: "10px", "--bs-navbar-toggler-focus-width": "0" }} aria-controls="basic-navbar-nav" toggle={isNavbarExpanded.toString()} onClick={toggleNavbar}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          {isNavbarExpanded ? <FaBarsStaggered /> : <FaBars />}
        </div>
      </Navbar.Toggle>
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="me-auto">
          <Nav.Link href="/home">Home</Nav.Link>
          <Nav.Link href="/folders">Data Trees</Nav.Link>
          <Nav.Link href="/forms">Data Travellers</Nav.Link>
          <Nav.Link href="/responses">Data Dispatch</Nav.Link>
        </Nav>
        <Nav style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
          <div style={{ padding: isMobile ? "20px 0" : "0 10px 0 0" }}>
            <p style={{ marginBottom: "0rem" }}>{userData.email}</p>
          </div>
          <div style={{ padding: isMobile ? "10px 0" : "0 25px 0 0" }}>
            <Dropdown isopen={isOpen.toString()} toggle={toggle.toString()} align={{ lg: "end" }} >
              <Dropdown.Toggle variant="transparent">
                <Avatar name={`${userData.first_name} ${userData.last_name}`} size="50" src={userData.profile_picture} round />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item disabled>
                  Signed in as {`${userData.first_name} ${userData.last_name}`}
                </Dropdown.Item>
                <Dropdown.Item onClick={goToProfile} style={{
                  "--bs-dropdown-link-active-bg": "#50011a",
                }}>
                  Profile/Administration
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item style={{
                  "--bs-dropdown-link-active-bg": "transparent",
                  cursor: "default"
                }}>
                  <Logout />
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </Navbar.Collapse>
    </StyledNavbar >
  );
}

export default ThemeNavbar;
