const baseURL = process.env.NODE_ENV === "development" ? "http://localhost:5000" : "";

/**
 * Adds a user to the system.
 *
 * @param {string} email - The email of the user.
 * @param {string} firstName - The first name of the user.
 * @param {string} lastName - The last name of the user.
 * @param {string} role - The role of the user.
 * @return {Promise<Object>} A promise that resolves to an object containing the status and data of the response.
 */
export const addUser = async (email, firstName, lastName, role) => {
  try {
    const response = await fetch(`${baseURL}/api/admin/addUser?email=${email}&firstName=${firstName}&lastName=${lastName}&role=${role}`,
      { method: "POST", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };

  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves all users from the server.
 *
 * @return {Object} An object containing the response status and data if successful, otherwise status only.
 */
export const getAllUsers = async () => {
  try {
    const response = await fetch(`${baseURL}/api/admin/getAllUsers`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};


/**
 * Updates the role of a user.
 *
 * @param {string} email - The email of the user.
 * @param {string} role - The new role for the user.
 * @return {Promise<Object>} An object containing the response status and data if successful, otherwise status only.
 */
export const updateUserRole = async (email, role) => {
  try {
    const response = await fetch(`${baseURL}/api/admin/updateUserRole?email=${email}&role=${role}`,
      { method: "POST", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};


/**
 * Retrieves user information by email.
 *
 * @param {string} email - The email of the user to retrieve.
 * @return {Promise<Object>} An object containing the response status and data if successful, otherwise status only.
 */
export const getUserByEmail = async (email) => {
  try {
    const response = await fetch(`${baseURL}/api/admin/getUserByEmail?email=${email}`,
      { method: "GET", credentials: "include" }
    );
    // console.log(response);
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Retrieves all groups from the server.
 *
 * @return {Promise<Object>} An object containing the status code and the data if the request is successful,
 *                           or an object with only the status code if the request fails.
 */
export const getAllGroups = async () => {
  try {
    const response = await fetch(`${baseURL}/api/admin/getAllGroups`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

/**
 * Adds a new group with the given name.
 *
 * @param {string} name - The name of the group to be added.
 * @return {Promise<Object>} An object containing the status code and the data if the request is successful,
 *                           or an object with only the status code if the request fails.
 * @throws {Error} If there is an error during the fetch request.
 */
export const addGroup = async (name) => {
  console.log(name);
  try {
    const response = await fetch(`${baseURL}/api/admin/addGroup?name=${name}`, {
      method: "POST",
      credentials: "include"
    });
    if (response.status === 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

export const getGroupById = async (groupId) => {
  try {
    const response = await fetch(`${baseURL}/api/admin/getGroupById?groupId=${groupId}`,
      { method: "GET", credentials: "include" }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};


export const addUsersToGroup = async (groupId, userIds) => {
  try {
    const response = await fetch(`${baseURL}/api/admin/addUsersToGroup`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ groupId, userIds })
    }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};

export const removeUsersFromGroup = async (groupId, userIds) => {
  try {
    const response = await fetch(`${baseURL}/api/admin/removeUsersFromGroup`, {
      method: "POST",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ groupId, userIds })
    }
    );
    if (response.status == 200) {
      return {
        status: response.status,
        data: await response.json(),
      };
    }
    return {
      status: response.status,
    };
  } catch (error) {
    console.error(error);
    return {
      status: 520,
    };
  }
};