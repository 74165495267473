import React, { useState, useEffect } from "react";
import "./../styles/rootStyle.css";
import GoogleLoginButton from "./../components/googleLoginButton.jsx";
import { useNavigate } from "react-router-dom";
import { validateUser, initLogin } from "../apis/authorizationApis.js";
import smallTamuLogo from "../assets/images/tamu_white_logo_small.png";
import dataScribeLogo from "../assets/images/DataScribeLogo.png";

/**
 * Renders the landing page component.
 *
 * @param {Object} setUser - A function to set the user state.
 * @return {JSX.Element} The rendered landing page component.
 */
function LandingPage({ setUser }) {
  const [isUserValidated, setIsUserValidated] = useState(false);
  const [serverError, setServerError] = useState("");
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    checkActiveSession();
    updateHeaderImage();
    window.addEventListener("resize", updateHeaderImage);
    return () => window.removeEventListener("resize", updateHeaderImage);
  }, []);

  const checkActiveSession = async () => {
    setIsAuthenticating(true);
    try {
      const response = await initLogin();
      if (response.status === 200) {
        setIsUserValidated(true);
        setServerError("");
        localStorage.setItem("user", JSON.stringify(response.data.userInfo));
        setUser(response.data.userInfo);
        navigate("/home");
      } else {
        setIsAuthenticating(false);
      }
    } catch (error) {
      setIsAuthenticating(false);
      console.error("Error checking active session:", error);
    }
  };

  const updateHeaderImage = () => {
    const img = document.getElementById("custom-header");
    if (img) {
      if (window.innerWidth < 700) {
        img.src = smallTamuLogo;
        img.alt = "Texas A&M Logo";
        img.style.width = "18.5vw";
        img.style.height = "auto";
      } else {
        img.src = "https://birdshot.tamu.edu/wp-content/themes/coe-theme/images/logos/coe/logos-horizontal/ENGINEERING_logo_HWHT.png";
        img.alt = "Texas A&M University College of Engineering";
        img.style.width = "auto";
        img.style.height = "auto";
      }
    }
  };


  /**
     * Handles the Google login process by validating the user and fetching user information.
     *
     * @param {string} USER_GOOGLE_AUTH_CODE - The Google authirization code sent by the user.
     * @return {Promise<void>} - A promise that resolves when the login process is complete.
     */
  const handleGoogleLogin = async (USER_GOOGLE_AUTH_CODE) => {
    console.log(USER_GOOGLE_AUTH_CODE);
    const response = await validateUser(USER_GOOGLE_AUTH_CODE);
    console.log(response.status);
    switch (response.status) {
      case 200:
        setIsUserValidated(true);
        setServerError("");
        localStorage.setItem("user", JSON.stringify(response.data.userInfo));
        setUser(response.data.userInfo);
        navigate("/home");
        break;
      case 403:
        setIsUserValidated(false);
        setServerError("403 Forbidden Access");
        break;
      case 404:
        setIsUserValidated(false);
        setServerError("404 Not Found");
        break;
      case 429:
        setIsUserValidated(false);
        setServerError("429 Too Many Requests");
        break;
      case 500:
        setIsUserValidated(false);
        setServerError("500 Internal Server Error");
        break;
      case 503:
        setIsUserValidated(false);
        setServerError("503 Service Unavailable");
        break;
      default:
        setIsUserValidated(false);
        setServerError("Unexpected Server Error");
    }
  };

  return (
    <div className='site-container'>
      <header className="site-header">
        <div className="title-area">
          <div className="site-title">
            <img src={dataScribeLogo} alt="DataScribe Logo" className="datascribe-logo" />
            {/* <h1 style={{ marginBottom: "0", paddingTop: "5px" }}>Datascribe.Cloud</h1> */}
            {/* <a style={{ fontSize: "10px", marginLeft: "5px" }} href="https://birdshot.tamu.edu/">part of BIRDSHOT center</a> */}
          </div>
        </div>
        <div className="widget-area header-widget-area">
          <a id="site-logo" href="https://birdshot.tamu.edu" style={{ display: "block", maxWidth: "350px" }}>
            <img id="custom-header" src="https://birdshot.tamu.edu/wp-content/themes/coe-theme/images/logos/coe/logos-horizontal/ENGINEERING_logo_HWHT.png" alt="Texas A&M University College of Engineering" />
          </a>
        </div>
      </header>
      <body className='site-body'>
        {isAuthenticating ? (
          <div className="signing-in-modal">
            <p>Signing you in...</p>
          </div>
        ) : (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", marginTop: "20px", marginBottom: "20px" }}>
            <div className="container py-4 bg-white rounded shadow-sm">
              <h2 className="display-4 mb-4">Datascribe Studio</h2>
              <p>
                This webapp is intended to aid in the creation of folder structures in Google Drive, as well as provide a framework for creating, sharing, and filling out forms with pertinent data.
              </p>
              <h4 className="mb-4" style={{ fontSize: "1.5rem" }}>Datascribe Information</h4>
              <p>
                At Datascribe.cloud, <b>Our vision</b> is to support researchers and small businesses in unlocking the full potential of their data through accessible and affordable cloud-based solutions. We strive to be the go-to platform for innovative data management and analytics, empowering our users to drive impactful discoveries and informed decisions. By focusing on simplicity, reliability, and exceptional support, we aim to foster a collaborative environment that accelerates research and growth.
              </p>
              <p>
                At Datascribe.cloud, <b>Our mission</b> is to accelerate research with innovative, scalable, and secure cloud-based data solutions. We aim to simplify data management, enhance analytics capabilities, and drive informed decision-making by providing intuitive, reliable, and cost-effective services tailored to meet the unique needs of reseachers.
              </p>
            </div>
            <h5 className='body-title' style={{ marginTop: "10px" }}>Sign In to Datascribe</h5>
            <GoogleLoginButton
              validateUserFunction={handleGoogleLogin}
            ></GoogleLoginButton>
            {isUserValidated === false && serverError && (
              <div className="server-error-container">
                <p className="login-response">{serverError}</p>
                <p>
                  Contact our development team at{" "}
                  <a href="mailto:divyanshu@tamu.edu">divyanshu@tamu.edu</a>, and{" "}
                  <a href="mailto:c2003@tamu.edu">c2003@tamu.edu</a>
                </p>
              </div>
            )}
          </div>
        )}
      </body>
      <footer className="footer">
        <div className="container">
          <p className="text-center" style={{color: "#50011a"}}>
            Copyright &copy; {new Date().getFullYear()} Datascribe.cloud
          </p>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;
