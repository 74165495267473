import React, { useEffect, useState, useRef } from "react";
import { Modal, Dropdown } from "react-bootstrap";
import {
  FaFolder, FaFolderOpen, FaRegSquarePlus, FaFolderPlus, FaFolderMinus, FaFolderTree,
  FaCheck, FaXmark, //FaPenToSquare
} from "react-icons/fa6";
import { BiRename } from "react-icons/bi";
import { LuBookTemplate } from "react-icons/lu";
import { getTemplateFolderStructure, getFolderStructureById, addFolderStructure, getIsFolderStructureValid } from "../apis/folderApis";
import ThemeButton from "../components/themeButton";

const AddFolderStructureModal = ({ show, onHide, structureTemplate, setViewFolderStructureResponse }) => {
  const [folderStructure, setFolderStructure] = useState(null);
  const [structureName, setStructureName] = useState("");
  const [numberOfFolders, setNumberOfFolders] = useState(0);
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [expandedFolders, setExpandedFolders] = useState([]);
  const [hoveredFolder, setHoveredFolder] = useState(null);
  const [editingFolderName, setEditingFolderName] = useState(null);
  const [newFolderName, setNewFolderName] = useState("");
  const [addingChildToFolder, setAddingChildToFolder] = useState(null);
  const [newChildFolderName, setNewChildFolderName] = useState("");
  const [replicatingFolder, setReplicatingFolder] = useState(null);
  const [numCopies, setNumCopies] = useState(1);
  const [availableTemplates, setAvailableTemplates] = useState([]);
  const [showTemplateDropdown, setShowTemplateDropdown] = useState(false);
  const [addingChildTemplateToFolder, setAddingChildTemplateToFolder] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [confirmingTemplate, setConfirmingTemplate] = useState(false);
  const [isInitialChoice, setIsInitialChoice] = useState(true);
  const [editingStructureName, setEditingStructureName] = useState(false);
  const [newStructureName, setNewStructureName] = useState(structureName);
  const folderStructureContainerRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const fileInputRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);

  // const [isValidJson, setIsValidJson] = useState(null);

  const handleEditStructureName = () => {
    setEditingStructureName(true);
    setNewStructureName(structureName);
  };

  const handleConfirmStructureRename = () => {
    setStructureName(newStructureName);
    setEditingStructureName(false);
  };

  const handleCancelStructureRename = () => {
    setEditingStructureName(false);
    setNewStructureName(structureName);
  };

  const handleInitialTemplateSelection = (template) => {
    if (template) {
      fetchFolderStructure(template.id);
    } else {
      setFolderStructure({ name: "root", children: [] });
      setNumberOfFolders(1);
      setStructureName("");
    }
    setIsInitialChoice(false);
  };

  const handleTemplateSelection = (template) => {
    setSelectedTemplate(template);
    setShowTemplateDropdown(false);
    setConfirmingTemplate(true);
  };

  const cancelTemplateSelection = () => {
    setSelectedTemplate(null);
    setConfirmingTemplate(false);
  };

  const confirmTemplateSelection = () => {
    if (selectedTemplate) {
      const updatedFolderStructure = { ...folderStructure };
      handleAddChildTemplate(updatedFolderStructure, addingChildTemplateToFolder, selectedTemplate);
      setFolderStructure(updatedFolderStructure);
      setAddingChildTemplateToFolder(null);
      setConfirmingTemplate(false);
      setSelectedTemplate(null);
    }
  };

  const handleAddChildTemplate = async (folderStructure, parentFolderPath, template) => {
    // Fetch the folder structure of the template
    const response = await getFolderStructureById(template.id);
    if (response.status >= 200 && response.status < 300) {
      const newChild = response.data[0].structure;

      const updateFolderRecursively = (folder, pathParts) => {
        // Check if the current folder matches the pathParts
        if (pathParts.length === 1 && folder.name === pathParts[0]) {
          // If so, add the template to this folder
          if (!folder.children) {
            folder.children = [];
          }

          // Check if the template name already exists
          let newName = newChild.name;
          let counter = 0;
          while (folder.children.some(child => child.name === newName)) {
            counter++;
            newName = `${newChild.name}-${counter}`;
          }

          console.log("Adding child template:", newName);
          folder.children.push({ ...newChild, name: newName });
          return true; // Exit the function after adding the template
        } else if (folder.children) {
          // If the current folder has children, iterate through them
          folder.children.map(child => {
            if (updateFolderRecursively(child, pathParts.slice(1))) {
              return true; // Exit the loop if the template is added
            }
            return false; // Indicate that the template was not added
          });
        }
        return false; // Indicate that the template was not added
      };

      // Split the parent folder path into parts
      const pathParts = parentFolderPath.split("/").filter(part => part.trim() !== "");

      setNumberOfFolders(numberOfFolders + response.data[0].number_of_folders);
      // Call the recursive function to update the folder
      updateFolderRecursively(folderStructure, pathParts);
    } else {
      setViewFolderStructureResponse(response.status);
      onHide();
    }
  };

  const countFolders = (folderStructure) => {
    let count = 1; // Count the current folder

    if (folderStructure.children) {
      for (const child of folderStructure.children) {
        count += countFolders(child);
      }
    }

    return count;
  };

  const handleDeleteFolder = (folderPathToDelete) => {
    setFolderStructure((prevFolderStructure) => {
      const updatedFolderStructure = { ...prevFolderStructure };
      deleteFolder(updatedFolderStructure, folderPathToDelete);
      return updatedFolderStructure;
    });
  };

  const deleteFolder = (folderStructure, folderPathToDelete) => {
    const pathPartsToDelete = folderPathToDelete.split("/").filter(part => part.trim() !== "");

    const updateFolderRecursively = (folder, pathParts) => {
      // Check if the current folder matches the pathParts
      if (pathParts.length === 1 && folder.name === pathParts[0]) {
        return null; // If so, remove the folder
      } else if (folder.children) {
        // If the current folder has children, iterate through them
        folder.children = folder.children.flatMap((child) => {
          const updatedChild = updateFolderRecursively(child, pathParts.slice(1));
          return updatedChild !== null ? [updatedChild] : [];
        });
      }

      return folder;
    };

    // Call the recursive function to update the folder
    const folderStructureCount = countFolders(folderStructure);
    const updatedFolderStructure = updateFolderRecursively(folderStructure, pathPartsToDelete);
    const updatedfolderStructureCount = countFolders(updatedFolderStructure);
    const deletedFolderCount = folderStructureCount - updatedfolderStructureCount;
    setNumberOfFolders(numberOfFolders - deletedFolderCount);
    return updatedFolderStructure;
  };

  const handleReplicateFolder = (folderPathToReplicate) => {
    setReplicatingFolder(folderPathToReplicate);
    setNumCopies(1);
  };

  const handleConfirmReplicateFolder = () => {
    if (replicateFolder) {
      const updatedFolderStructure = { ...folderStructure };
      const parentFolder = getParentFolder(updatedFolderStructure, replicatingFolder);
      const replicatedFolders = replicateFolder(replicatingFolder, numCopies, parentFolder);
      const replicatedFolderCount = replicatedFolders.reduce((count, folder) => count + countFolders(folder), 0);

      if (parentFolder) {
        parentFolder.children = [...parentFolder.children, ...replicatedFolders];
        setNumberOfFolders(numberOfFolders + replicatedFolderCount);
      } else {
        updatedFolderStructure.children = [...updatedFolderStructure.children, ...replicatedFolders];
        setNumberOfFolders(numberOfFolders + replicatedFolderCount);
      }

      setFolderStructure(updatedFolderStructure);
      setReplicatingFolder(null);
    }
  };

  const replicateFolder = (folderPathToReplicate, numCopies, parentFolder) => {
    const folderNameToReplicate = folderPathToReplicate.split("/").pop();
    const replicatedFolders = [];

    if (parentFolder) {
      const existingNames = new Set(parentFolder.children.map(child => child.name));
      for (let i = 1; i <= numCopies; i++) {
        let newName = `${folderNameToReplicate}-${i}`;
        let count = 1;
        while (existingNames.has(newName)) {
          count++;
          newName = `${folderNameToReplicate}-${count}`;
        }
        existingNames.add(newName);
        const folderCopy = JSON.parse(JSON.stringify(parentFolder.children.find(child => child.name === folderNameToReplicate)));
        folderCopy.name = newName;
        replicatedFolders.push(folderCopy);
      }
    }

    return replicatedFolders;
  };

  const getParentFolder = (folderStructure, folderPath) => {
    const pathParts = folderPath.split("/").filter(part => part.trim() !== "");
    return findParentRecursively(folderStructure, pathParts);
  };

  const findParentRecursively = (folder, pathParts) => {
    if (pathParts.length === 2) {
      return folder;
    }

    const nextFolderName = pathParts[1];
    const nextFolderIndex = folder.children.findIndex(child => child.name === nextFolderName);
    if (nextFolderIndex !== -1) {
      return findParentRecursively(folder.children[nextFolderIndex], pathParts.slice(1));
    }

    return null;
  };

  const handleAddChildFolder = (folderName) => {
    setAddingChildToFolder(folderName);
    setNewChildFolderName("");
  };

  const handleConfirmAddChildFolder = () => {
    // console.log("Adding child folder:", newChildFolderName);
    const updatedFolderStructure = { ...folderStructure }; // Make a copy of the current folder structure
    addChildFolder(updatedFolderStructure, addingChildToFolder, newChildFolderName); // Add the new child folder
    setFolderStructure(updatedFolderStructure); // Update the folder structure state
    setAddingChildToFolder(null);
    setNewChildFolderName("");
  };

  const addChildFolder = (folderStructure, parentFolderPath, newChildFolderName) => {
    const updateFolderRecursively = (folder, pathParts) => {
      // Check if the current folder matches the pathParts
      if (pathParts.length === 1) {
        // Check if the folder has children
        if (!Array.isArray(folder.children)) {
          folder.children = [];
        }
        // If so, check if a child folder with the same name already exists
        const existingChildIndex = folder.children.findIndex(child => child.name === newChildFolderName);
        if (existingChildIndex !== -1) {
          alert("A child folder with the same name already exists.");
          return; // Exit the function if a child with the same name exists
        }
        // Add the new child folder
        if (!folder.children) {
          folder.children = []; // Initialize children array if it doesn't exist
        }
        setNumberOfFolders(numberOfFolders + 1);
        folder.children.push({ name: newChildFolderName, children: [] }); // Add the new child folder
        return;
      }

      // Find the index of the child folder with the next path part
      const nextFolderIndex = folder.children.findIndex(child => child.name === pathParts[1]);
      if (nextFolderIndex !== -1) {
        // Recursively update the next child folder
        updateFolderRecursively(folder.children[nextFolderIndex], pathParts.slice(1));
      }
    };

    if (!newChildFolderName) {
      alert("Please enter a name for the new child folder.");
      return;
    }

    // Split the parent folder path into parts
    const pathParts = parentFolderPath.split("/").filter(part => part.trim() !== "");

    // Call the recursive function to update the folder
    updateFolderRecursively(folderStructure, pathParts);
  };

  const handleRenameFolder = (folderPath) => {
    setEditingFolderName(folderPath);
    setNewFolderName(getFolderNameFromPath(folderPath));
  };

  const getFolderNameFromPath = (folderPath) => {
    // Extract the folder name from the path
    const parts = folderPath.split("/");
    return parts[parts.length - 1];
  };

  const handleConfirmRename = () => {
    // Update the folderStructure with the new name
    const updatedFolderStructure = { ...folderStructure };
    updateFolderName(updatedFolderStructure, editingFolderName, newFolderName);
    setEditingFolderName(null);
    setNewFolderName(null);
    setFolderStructure(updatedFolderStructure);
  };

  const updateFolderName = (folderStructure, pathOfFolderToRename, newFolderName) => {
    const updateFolderRecursively = (folder, pathParts) => {
      // Check if the current folder matches the pathParts
      if (pathParts.length === 1) {
        folder.name = newFolderName; // Update the name of the folder
        return;
      }

      // Check if a child folder with the same name already exists
      if (pathParts.length === 2) {
        const existingChildIndex = folder.children.findIndex(child => child.name === newFolderName);
        console.log(existingChildIndex);
        if (existingChildIndex !== -1) {
          alert("A folder with the same name already exists in this directory.");
          return;
        }
      }

      // Find the index of the child folder with the next path part
      const nextFolderIndex = folder.children.findIndex(child => child.name === pathParts[1]);
      if (nextFolderIndex !== -1) {
        // Recursively update the next child folder
        updateFolderRecursively(folder.children[nextFolderIndex], pathParts.slice(1));
      }
    };

    if (!newFolderName) {
      alert("Please enter a new folder name.");
      return;
    }


    // Split the path into parts
    const pathParts = pathOfFolderToRename.split("/");

    // Call the recursive function to update the folder name
    updateFolderRecursively(folderStructure, pathParts);
  };

  const handleCancelRename = () => {
    setEditingFolderName(null);
    setNewFolderName("");
  };

  useEffect(() => {
    if (show) {
      const userFromStorage = JSON.parse(localStorage.getItem("user"));
      if (userFromStorage) {
        setProfile(userFromStorage);
        fetchAvailableTemplates();
        setViewFolderStructureResponse(200);
        window.addEventListener("resize", handleResize);
      } else {
        setViewFolderStructureResponse(401);
        onHide();
      }
    }
  }, [show, onHide]);

  useEffect(() => {
    // This effect will run whenever the folderStructure state changes
    // You can add any additional dependencies if needed
    if (folderStructureContainerRef.current) {
      folderStructureContainerRef.current.scrollLeft = folderStructureContainerRef.current.scrollWidth;
    }
  }, [folderStructure, editingFolderName, addingChildToFolder, replicatingFolder, addingChildTemplateToFolder, selectedTemplate]);

  const handleResize = () => {
    if (window.innerWidth < 750) {
      setIsMobile(true);
    }
    else {
      setIsMobile(false);
    }
  };
  const fetchFolderStructure = async (folderStructureID) => {
    setIsLoading(true);
    const response = await getFolderStructureById(folderStructureID);
    if (response.status >= 200 && response.status < 300) {
      const folderData = response.data[0];
      setFolderStructure(folderData.structure);
      setStructureName(folderData.name);
      setNumberOfFolders(folderData.number_of_folders);
    } else {
      setViewFolderStructureResponse(response.status);
      onHide();
    }
    setIsLoading(false);
  };

  const fetchAvailableTemplates = async () => {
    console.log("fetching available templates");
    setIsLoading(true);
    const response = await getTemplateFolderStructure();
    console.log(response);
    if (response.status >= 200 && response.status < 300) {
      console.log(response.data);
      setAvailableTemplates(response.data);
    } else {
      setViewFolderStructureResponse(response.status);
      onHide();
    }
    setIsLoading(false);
  };

  const generateTextRepresentation = (folder, prefix = "", level = 0) => {
    level += 1;
    let text = `${prefix.slice(0, -4 + (level - 1) * -3)}${folder.name}\n`;
    if (folder.children && folder.children.length > 0) {
      prefix += "";
      folder.children.forEach((child, index, children) => {
        text += prefix;
        if (index === children.length - 1) {
          text += "└───";
          prefix += "    ";
        } else {
          text += "│───";
          prefix += "│   ";
        }
        text += generateTextRepresentation(child, prefix, level);
        prefix = prefix.slice(0, -4);
      });
    }

    return text;
  };

  const toggleFolder = (folderName) => {
    setExpandedFolders((prevExpandedFolders) => {
      if (prevExpandedFolders.includes(folderName)) {
        return prevExpandedFolders.filter((folder) => folder !== folderName);
      } else {
        return [...prevExpandedFolders, folderName];
      }
    });
  };

  const renderFolderStructure = (folder, parentPath = "") => {
    const folderPath = parentPath ? `${parentPath}/${folder.name}` : folder.name;
    const isExpanded = expandedFolders.includes(folderPath);
    const isHovered = hoveredFolder === folderPath;
    const isRootFolder = !parentPath; // Check if the current folder is the root folder
    const isEditingThisFolder = editingFolderName === folderPath;
    const isAddingChildToThisFolder = addingChildToFolder === folderPath;
    const isReplicatingThisFolder = replicatingFolder === folderPath;
    const isAddingChildTemplateToThisFolder = addingChildTemplateToFolder === folderPath;


    return (
      <div style={{ marginBottom: "5px", paddingLeft: "10px", borderLeft: "2px solid transparent", whiteSpace: "nowrap" }}>
        <div
          style={{
            display: "flex", alignItems: "center", cursor: "pointer", padding: "5px", borderRadius: "3px",
            transition: "background-color 0.3s", backgroundColor: isHovered ? "#f0f0f0" : "transparent",
          }}
          onMouseEnter={() => setHoveredFolder(folderPath)}
          onMouseLeave={() => setHoveredFolder(null)}>
          <div
            style={{
              background: "none", border: "none", cursor: "pointer", marginRight: "5px",
              display: "flex", alignItems: "center", height: "fit-content",
            }}
            onClick={() => toggleFolder(folderPath)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                toggleFolder(folderPath);
              }
            }}
            role="button"
            tabIndex="0">
            {isExpanded ? <FaFolderOpen /> : <FaFolder />}
            {isEditingThisFolder ? (
              <input
                type="text"
                value={newFolderName}
                onChange={(e) => setNewFolderName(e.target.value)}
                style={{ marginLeft: "5px" }}
              />
            ) : (
              <span style={{ marginLeft: "5px" }}>{folder.name}</span>
            )}
          </div>


          {isHovered && !isEditingThisFolder && !isAddingChildToThisFolder && !isReplicatingThisFolder && !confirmingTemplate && (
            <div style={{ marginLeft: "auto", display: "flex", alignItems: "center", flexDirection: "row" }}>
              {isRootFolder ? (
                <>
                  <div
                    style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                    title="Rename folder"
                    onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                    onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                    onClick={() => handleRenameFolder(folderPath)}
                    onKeyDown={() => handleRenameFolder(folderPath)}
                    role="button"
                    tabIndex="0">
                    <BiRename />
                  </div>
                  <div
                    style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                    title="Add child template"
                    onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                    onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowTemplateDropdown(!showTemplateDropdown);
                        setAddingChildTemplateToFolder(folderPath);
                      }}
                      onKeyDown={() => {
                        setShowTemplateDropdown(!showTemplateDropdown);
                        setAddingChildTemplateToFolder(folderPath);
                      }}
                      role="button"
                      tabIndex="0">
                      <LuBookTemplate />
                    </div>
                    <div className="dropdown-container" style={{ position: isMobile ? "" : "absolute", zIndex: "1" }}>
                      <Dropdown show={showTemplateDropdown} onToggle={setShowTemplateDropdown} align={{ lg: "end" }}>
                        <Dropdown.Menu>
                          {availableTemplates.map((template) => (
                            <Dropdown.Item key={template.id} onClick={() => handleTemplateSelection(template)}
                              style={{ fontSize: isMobile ? "10px" : "inherit" }}>
                              {template.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div
                    style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s" }}
                    title="Add child folder"
                    onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                    onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                    onClick={() => handleAddChildFolder(folderPath)}
                    onKeyDown={() => handleAddChildFolder(folderPath)}
                    role="button"
                    tabIndex="0">
                    <FaFolderPlus />
                  </div>
                </>
              ) : (
                <>
                  <div
                    style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                    title="Rename folder"
                    onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                    onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                    onClick={() => handleRenameFolder(folderPath)}
                    onKeyDown={() => handleRenameFolder(folderPath)}
                    role="button"
                    tabIndex="0">
                    <BiRename />
                  </div>
                  <div
                    style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s" }}
                    title="Replicate folder"
                    onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                    onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                    onClick={() => handleReplicateFolder(folderPath)}
                    onKeyDown={() => handleReplicateFolder(folderPath)}
                    role="button"
                    tabIndex="0">
                    <FaFolderTree />
                  </div>
                  <div
                    style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                    title="Add child template"
                    onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                    onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}>
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setShowTemplateDropdown(!showTemplateDropdown);
                        setAddingChildTemplateToFolder(folderPath);
                      }}
                      onKeyDown={() => {
                        setShowTemplateDropdown(!showTemplateDropdown);
                        setAddingChildTemplateToFolder(folderPath);
                      }}
                      role="button"
                      tabIndex="0">
                      <LuBookTemplate />
                    </div>
                    <div className="dropdown-container" style={{ position: isMobile ? "" : "absolute", zIndex: "1" }}>
                      <Dropdown show={showTemplateDropdown} onToggle={setShowTemplateDropdown} align={{ lg: "end" }}>
                        <Dropdown.Menu>
                          {availableTemplates.map((template) => (
                            <Dropdown.Item key={template.id} onClick={() => handleTemplateSelection(template)}
                              style={{ fontSize: isMobile ? "10px" : "inherit" }}>
                              {template.name}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <div
                    style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s" }}
                    title="Add child folder"
                    onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                    onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                    onClick={() => handleAddChildFolder(folderPath)}
                    onKeyDown={() => handleAddChildFolder(folderPath)}
                    role="button"
                    tabIndex="0">
                    <FaFolderPlus />
                  </div>
                  <div
                    style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                    title="Delete this folder"
                    onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                    onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                    onClick={() => handleDeleteFolder(folderPath)}
                    onKeyDown={() => handleDeleteFolder(folderPath)}
                    role="button"
                    tabIndex="0">
                    <FaFolderMinus />
                  </div>
                </>
              )}
            </div>
          )}


          {isReplicatingThisFolder && (
            <div style={{ marginLeft: "auto", display: "flex", alignItems: "center", flexDirection: "row" }}>
              <input
                type="number"
                value={numCopies}
                onChange={(e) => setNumCopies(e.target.value)}
                min="1"
                style={{ marginRight: "5px" }}
              />
              <div
                style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s" }}
                onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                title="Confirm replicate folder"
                onClick={handleConfirmReplicateFolder}
                onKeyDown={handleConfirmReplicateFolder}
                role="button"
                tabIndex="0"
              >
                <FaCheck />
              </div>
              <div
                style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s" }}
                title="Cancel replicate folder"
                onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                onClick={() => setReplicatingFolder(null)}
                onKeyDown={() => setReplicatingFolder(null)}
                role="button"
                tabIndex="0"
              >
                <FaXmark />
              </div>
            </div>
          )}


          {isAddingChildToThisFolder && (
            <div style={{ marginLeft: "auto", display: "flex", alignItems: "center", flexDirection: "row" }}>
              <input
                type="text"
                value={newChildFolderName}
                onChange={(e) => setNewChildFolderName(e.target.value)}
                placeholder="Enter child folder name"
                style={{ marginRight: "5px" }}
              />
              <div
                style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s" }}
                onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                title="Confirm add child folder"
                onClick={handleConfirmAddChildFolder}
                onKeyDown={handleConfirmAddChildFolder}
                role="button"
                tabIndex="0"
              >
                <FaCheck />
              </div>
              <div
                style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s" }}
                title="Cancel add child folder"
                onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                onClick={() => setAddingChildToFolder(null)}
                onKeyDown={() => setAddingChildToFolder(null)}
                role="button"
                tabIndex="0"
              >
                <FaXmark />
              </div>
            </div>
          )}


          {isEditingThisFolder && (
            <div style={{ marginLeft: "auto", display: "flex", alignItems: "center", flexDirection: "row" }}>
              <div
                style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                title="Confirm rename"
                onClick={handleConfirmRename}
                onKeyDown={handleConfirmRename}
                role="button"
                tabIndex="0"
              >
                <FaCheck />
              </div>
              <div
                style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                title="Cancel rename"
                onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                onClick={handleCancelRename}
                onKeyDown={handleCancelRename}
                role="button"
                tabIndex="0"
              >
                <FaXmark />
              </div>
            </div>
          )}


          {confirmingTemplate && isAddingChildTemplateToThisFolder && (
            <div style={{ marginLeft: "auto", display: "flex", alignItems: "center", flexDirection: "row" }}>
              <span style={{ marginRight: "5px", color: "#50011a", fontSize: "11px" }}>
                Add template <b>{selectedTemplate.name}</b>
              </span>
              <div
                style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                title="Confirm rename"
                onClick={confirmTemplateSelection}
                onKeyDown={confirmTemplateSelection}
                role="button"
                tabIndex="0">
                <FaCheck />
              </div>
              <div
                style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                title="Cancel rename"
                onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                onClick={cancelTemplateSelection}
                onKeyDown={cancelTemplateSelection}
                role="button"
                tabIndex="0">
                <FaXmark />
              </div>
            </div>
          )}
        </div>


        {isExpanded && folder.children && folder.children.length > 0 && (
          <div style={{ marginLeft: "20px" }}>
            {folder.children.map((child) => (
              <div key={child.name}>{renderFolderStructure(child, folderPath)}</div>
            ))}
          </div>
        )}

      </div>
    );
  };

  const resetState = () => {
    setExpandedFolders([]);
    setHoveredFolder(null);
    setIsInitialChoice(true);
    setFolderStructure(null);
    setNumberOfFolders(0);
    setSelectedFile(null);
    setFileName("");
  };

  const handleAddFolderStructure = async (folderStructure, numberOfFolders, structureName, template) => {
    if (!structureName || structureName.trim() === "") {
      alert("Please provide a name for the folder structure.");
      return;
    }

    console.log(folderStructure, numberOfFolders, structureName, template);
    const response = await addFolderStructure(folderStructure, numberOfFolders, structureName, template);
    switch (response.status) {
      case 200:
        alert("Folder structure added successfully.");
        setViewFolderStructureResponse(response.status);
        resetState();
        onHide();
        break;
      case 400:
        alert("Error: Bad request. Please check your input and try again.");
        setViewFolderStructureResponse(response.status);
        onHide();
        break;
      case 401:
        alert("Error: Unauthorized. Please log in again.");
        setViewFolderStructureResponse(response.status);
        onHide();
        break;
      case 403:
        alert("Error: Forbidden. You do not have the necessary permissions to perform this action.");
        setViewFolderStructureResponse(response.status);
        onHide();
        break;
      case 409:
        alert("Error: A folder with the same name already exists. Please try a different name.");
        setViewFolderStructureResponse(response.status);
        onHide();
        break;
      default:
        alert("Error: Unexpected error occurred. Please try again later.");
        setViewFolderStructureResponse(response.status);
        resetState();
        onHide();
        break;
    }

  };

  const handleSelectFile = (e) => {
    const file = e.target.files[0];
    console.log("Selected file:" + file);
    if (file) {
      const fileName = encodeURIComponent(file.name); // Encode the file name
      setFileName(fileName);
      const reader = new FileReader();
      reader.onload = (event) => {
        const fileContent = event.target.result;
        if (fileContent.trim().length === 0) {
          setSelectedFile("NULL");
        } else {
          setSelectedFile(fileContent);
        }
      };
      reader.readAsText(file);
    }
  };

  const validateJsonFile = async () => {

    const jsonFromFile = selectedFile;
    const response = await getIsFolderStructureValid(jsonFromFile);
    if (response.status === 200) {
      setFolderStructure(JSON.parse(jsonFromFile));
      setNumberOfFolders(countFolders(JSON.parse(jsonFromFile)));
      setStructureName("");
      setIsInitialChoice(false);
    }
    else {
      switch (response.status) {
        case 400:
          alert("Error: Bad request. Please check your input and try again.");
          setViewFolderStructureResponse(response.status);
          resetState();
          onHide();
          break;
        case 401:
          alert("Error: Unauthorized. Please log in again. Signed in as: " + profile.email);
          setViewFolderStructureResponse(response.status);
          resetState();
          onHide();
          break;
        case 403:
          setViewFolderStructureResponse(response.status);
          onHide();
          break;
        case 409:
          alert("Error: A folder with the same name already exists. Please try a different name.");
          setViewFolderStructureResponse(response.status);
          onHide();
          break;
        default:
          alert("Error: Unexpected error occurred. Please try again later.");
          setViewFolderStructureResponse(response.status);
          resetState();
          onHide();
          break;
      }
    }
  };

  const handleChooseFile = () => {
    fileInputRef.current.click();
  };

  return (
    <Modal show={show} onHide={() => {
      resetState();
      onHide();
    }} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ fontSize: isMobile ? "18px" : "" }}>{structureTemplate ? "Make a new Template Structure" : "Make a new Folder Structure"}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ minHeight: "300px", maxHeight: "650px", overflowY: "auto", maxWidth: "800px", overflowX: "auto" }}>
        {isInitialChoice ? (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", }}>
            <h5 style={{ fontSize: isMobile ? "18px" : "" }}>How would you like to start?</h5>
            <ThemeButton variant="outline-primary" onClick={() => handleInitialTemplateSelection(null)}
              style={{ marginLeft: "10px", marginRight: "10px", marginTop: "10px", fontSize: isMobile ? "14px" : "" }}>
              Start from scratch
            </ThemeButton>

            <Dropdown>
              <Dropdown.Toggle variant="outline-primary" align={{ lg: "end" }}
                style={{
                  "--bs-btn-color": "#50011a",
                  "--bs-btn-border-color": "#50011a",
                  "--bs-btn-hover-color": "#fff",
                  "--bs-btn-hover-bg": "#50011a",
                  "--bs-btn-hover-border-color": "#50011a",
                  "--bs-btn-active-color": "#fff",
                  "--bs-btn-active-bg": "#50011a",
                  "--bs-btn-active-border-color": "#50011a",
                  "--bs-btn-active-shadow": "inset 0 3px 5px rgba(0, 0, 0, 0.125)",
                  "--bs-btn-disabled-color": "#50011a",
                  "--bs-btn-disabled-bg": "transparent",
                  "--bs-btn-disabled-border-color": "#50011a",
                  marginTop: "10px",
                  fontSize: isMobile ? "14px" : "",
                }}>
                Base it off a template
              </Dropdown.Toggle>

              <Dropdown.Menu>
                {availableTemplates.map((template) => (
                  <Dropdown.Item key={template.id} onClick={() => handleInitialTemplateSelection(template)}
                    style={{ "--bs-dropdown-link-active-bg": "#50011a", fontSize: isMobile ? "14px" : "" }}>
                    {template.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>


            <input ref={fileInputRef} type="file" accept=".json" onChange={handleSelectFile} hidden />
            <ThemeButton
              variant="outline-primary" style={{
                marginLeft: "10px", marginRight: "10px", marginTop: "10px",
                fontSize: isMobile ? "14px" : ""
              }}
              onClick={handleChooseFile}>
              Upload a JSON file
            </ThemeButton>

            {fileName && <>Selected File: {fileName}</>}


            {selectedFile && (
              <ThemeButton
                variant="outline-primary"
                onClick={() => validateJsonFile(selectedFile)}
                style={{
                  marginLeft: "10px",
                  marginRight: "10px",
                  marginTop: "10px",
                }}>
                Use JSON
              </ThemeButton>
            )}
          </div>
        ) : isLoading ? (
          <div style={{ textAlign: "center" }}>
            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span className="sr-only">Loading...</span>
          </div>
        ) : folderStructure ? (
          <div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  {editingStructureName ? (
                    <input
                      type="text"
                      value={newStructureName}
                      onChange={(e) => setNewStructureName(e.target.value)}
                      style={{ marginLeft: "5px" }}
                    />
                  ) : (
                    <h5 style={{ fontSize: isMobile ? "18px" : "" }}>Structure Name: {structureName}</h5>
                  )}
                  {!editingStructureName && (
                    <ThemeButton
                      variant="outline-primary"
                      onClick={handleEditStructureName}
                      style={{ marginLeft: "10px", fontSize: isMobile ? "14px" : "" }}>
                      Edit
                    </ThemeButton>
                  )}
                  {editingStructureName && (
                    <div style={{ marginLeft: "auto", display: "flex", alignItems: "center", flexDirection: "row" }}>
                      <div
                        style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                        onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                        onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                        title="Confirm rename"
                        onClick={handleConfirmStructureRename}
                        onKeyDown={handleConfirmStructureRename}
                        role="button"
                        tabIndex="0"
                      >
                        <FaCheck />
                      </div>
                      <div
                        style={{ marginRight: "5px", paddingRight: "5px", color: "inherit", transition: "color 0.3s", }}
                        title="Cancel rename"
                        onMouseEnter={(e) => e.currentTarget.style.color = "#50011a"}
                        onMouseLeave={(e) => e.currentTarget.style.color = "inherit"}
                        onClick={handleCancelStructureRename}
                        onKeyDown={handleCancelStructureRename}
                        role="button"
                        tabIndex="0"
                      >
                        <FaXmark />
                      </div>
                    </div>
                  )}
                </div>
                <p style={{ fontSize: isMobile ? "14px" : "" }}>Number of Folders: {numberOfFolders}</p>
              </div>
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ flex: 1, border: "1px solid #50011a", borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px", marginRight: "5px", overflow: "hidden" }}>
                <h5 style={{
                  backgroundColor: "#50011a", color: "white", padding: "10px",
                  marginBlockEnd: "0px", fontSize: isMobile ? "14px" : "", whiteSpace: "nowrap",
                  overflowX: "auto"
                }}>
                  Interactive Collapsible Structure
                </h5>
                <div style={{
                  maxHeight: "450px", overflowY: "auto", padding: "10px", paddingRight: "20px",
                  overflowX: "auto", fontSize: isMobile ? "10px" : ""
                }} ref={folderStructureContainerRef}>
                  {renderFolderStructure(folderStructure)}
                </div>
              </div>
              <div style={{ flex: 1, border: "1px solid #50011a", borderBottomRightRadius: "10px", borderBottomLeftRadius: "10px", marginRight: "5px", overflow: "hidden" }}>
                <h5 style={{
                  backgroundColor: "#50011a", color: "white", padding: "10px",
                  marginBlockEnd: "0px", fontSize: isMobile ? "14px" : "", whiteSpace: "nowrap",
                  overflowX: "auto"
                }}>
                  Text Representation
                </h5>
                <div style={{ maxHeight: "450px", overflowY: "auto", padding: "10px" }}>
                  <pre style={{ fontSize: isMobile ? "10px" : "" }}>{generateTextRepresentation(folderStructure)}</pre>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "10px", marginTop: "10px", justifyContent: "flex-end" }}>
              <ThemeButton variant="outline-primary"
                onClick={() => handleAddFolderStructure(folderStructure, numberOfFolders, structureName, structureTemplate)}
              >
                <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <FaRegSquarePlus />
                  {structureTemplate ? "Save Template" : "Save Structure"}
                </div>
              </ThemeButton>
            </div>
          </div>
        ) : (
          <div>Error fetching folder structure</div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddFolderStructureModal;
