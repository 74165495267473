import React, { useEffect, useState, useRef } from "react";
import ThemeNavbar from "../components/themeNavbar.jsx";
import ThemeButton from "../components/themeButton.jsx";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import { FaPen, FaPlus, FaEye, FaDownload, FaTrash, FaFolder, FaAngleRight } from "react-icons/fa6";
import { getUserForms, getTemplateForms, getAllFields, deleteField, getFormById, deleteForm } from "../apis/formsApis";
import { getUserByEmail } from "../apis/userApis";
import ViewFieldModal from "../modals/viewFieldModal.jsx";
import EditFieldModal from "../modals/editFieldModal.jsx";
import AddFieldModal from "../modals/addFieldModal.jsx";
import ViewFormModal from "../modals/viewFormModal.jsx";
import EditFormModal from "../modals/editFormModal.jsx";
import AddFormModal from "../modals/addFormModal.jsx";
import FormFolderSelectionModal from "../modals/formFolderSelectionModal.jsx";
import background from "../assets/images/background4.png";

function FormPage() {

  const [userForms, setUserFroms] = useState(null);
  const [templateForms, setTemplateForms] = useState(null);
  const [fields, setFields] = useState(null);
  const [profile, setProfile] = useState(null);
  const [serverError, setServerError] = useState("");
  const [userRole, setUserRole] = useState(null);

  const [viewFieldModalShow, setViewFieldModalShow] = useState(false);
  const [viewFieldModalResponse, setViewFieldModalResponse] = useState("");
  const [viewFieldId, setViewFieldId] = useState(null);
  const [editFieldModalShow, setEditFieldModalShow] = useState(false);
  const [editFieldModalResponse, setEditFieldModalResponse] = useState("");
  const [editFieldId, setEditFieldId] = useState(null);
  const [addFieldModalShow, setAddFieldModalShow] = useState(false);
  const [addFieldModalResponse, setAddFieldModalResponse] = useState("");

  const [viewFormModalShow, setViewFormModalShow] = useState(false);
  const [viewFormModalResponse, setViewFormModalResponse] = useState("");
  const [viewFormId, setViewFormId] = useState(null);
  const [editFormModalShow, setEditFormModalShow] = useState(false);
  const [editFormModalResponse, setEditFormModalResponse] = useState("");
  const [editFormId, setEditFormId] = useState(null);
  const [addFormModalShow, setAddFormModalShow] = useState(false);
  const [addFormModalResponse, setAddFormModalResponse] = useState("");
  const [addFormModalTemplateValue, setAddFormModalTemplateValue] = useState(false);

  const [formFolderSelectionModalShow, setFormFolderSelectionModalShow] = useState(false);
  const [formFolderSelectionModalResponse, setFormFolderSelectionModalResponse] = useState("");
  const [formFolderSelectionModalFormId, setFormFolderSelectionModalFormId] = useState(null);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 750);
  const userFormsTableRef = useRef(null);
  const templateFormsTableRef = useRef(null);
  const fieldsTableRef = useRef(null);



  useEffect(() => {
    const userFromStorage = JSON.parse(localStorage.getItem("user"));
    if (!userFromStorage) {
      setServerError("401 Unauthorized Access"); // Set 401 if user is empty
    } else {
      setProfile(userFromStorage);
      fetchUserForms();
      fetchTemplateForms();
      fetchFields();
      fetchUserRole(userFromStorage.email);
      window.addEventListener("resize", handleResize);
    }
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 750);
  };

  const scrollTableRight = (tableRef) => {
    if (tableRef.current) {
      const scrollContainer = tableRef.current;
      const maxScrollLeft = scrollContainer.scrollWidth - scrollContainer.clientWidth;

      scrollContainer.scrollTo({
        left: maxScrollLeft,
        behavior: "smooth"
      });
    }
  };

  const fetchUserForms = async () => {
    const response = await getUserForms();
    if (response.status >= 200 && response.status < 300) {
      setUserFroms(response.data);
    } else {
      handleFetchError(response.status);
    }
  };

  const fetchTemplateForms = async () => {
    const response = await getTemplateForms();
    if (response.status >= 200 && response.status < 300) {
      setTemplateForms(response.data);
    } else {
      handleFetchError(response.status);
    }
  };

  const fetchFields = async () => {
    const response = await getAllFields();
    if (response.status >= 200 && response.status < 300) {
      setFields(response.data);
    } else {
      handleFetchError(response.status);
    }
  };

  const fetchUserRole = (email) => {
    getUserByEmail(email)
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          setUserRole(response.data.user.user_role);
        } else {
          handleFetchError(response.status);
        }
      })
      .catch((error) => {
        console.error(error);
        handleFetchError(503);
      });
  };

  const handleFetchError = (status) => {
    switch (status) {
      case 400:
        setServerError("400 Bad Request");
        break;
      case 401:
        setServerError("401 Unauthorized Access");
        break;
      case 403:
        setServerError("403 Forbidden Access");
        break;
      case 404:
        setServerError("404 Not Found");
        break;
      case 429:
        setServerError("429 Too Many Requests");
        break;
      case 500:
        setServerError("500 Internal Server Error");
        break;
      case 503:
        setServerError("503 Service Unavailable");
        break;
      default:
        setServerError("Unknown Error");
        break;
    }
  };

  const getQuestionTypeText = (questionType) => {
    switch (questionType) {
      case "shortAnswer":
        return "Short Answer";
      case "paragraph":
        return "Paragraph";
      case "checkboxes":
        return "Checkboxes";
      case "multipleChoice":
        return "Multiple Choice";
      case "dropdown":
        return "Dropdown";
      case "date":
        return "Date";
      case "time":
        return "Time";
      case "fileUpload":
        return "File Upload";
      case "scale":
        return "Scale";
      case "numeric":
        return "Numeric";
      default:
        return "Unknown";
    }
  };

  const handleViewFieldModal = (id) => {
    setViewFieldId(id);
    setViewFieldModalShow(true);
  };

  const handleEditFieldModal = (id) => {
    setEditFieldId(id);
    setEditFieldModalShow(true);
  };

  const handleAddFieldModal = () => {
    setAddFieldModalShow(true);
  };

  const handleViewFormModal = (id) => {
    setViewFormId(id);
    setViewFormModalShow(true);
  };

  const handleEditFormModal = (id) => {
    setEditFormId(id);
    setEditFormModalShow(true);
  };

  const handleAddFormModal = (templateValue) => {
    setAddFormModalShow(true);
    setAddFormModalTemplateValue(templateValue);
  };

  const handleDeleteField = (id) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this field?");

    if (confirmDelete) {
      deleteField(id)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            alert("Field deleted successfully!");
            fetchFields();
          } else {
            handleFetchError(response.status);
          }
        })
        .catch((error) => {
          console.error(error);
          handleFetchError(503);
        });
    }
  };

  const handleDownloadJSON = async (formID) => {
    const response = await getFormById(formID);
    if (response.status === 200 && response.data && response.data.length > 0) {
      const form = response.data[0];
      const fileName = `${form.name}.json`;
      const jsonData = JSON.stringify(form.structure, null, 2);
      const blob = new Blob([jsonData], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    } else {
      console.log(response);
      alert("Failed to download JSON. Error: " + response.status);
    }
  };

  const handleDeleteForm = (formID) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this form?");

    if (confirmDelete) {
      deleteForm(formID)
        .then((response) => {
          if (response.status >= 200 && response.status < 300) {
            alert("Form deleted successfully!");
            fetchTemplateForms();
            fetchUserForms();
          } else {
            handleFetchError(response.status);
          }
        })
        .catch((error) => {
          console.error(error);
          handleFetchError(503);
        });
    }
  };

  const handleSelectFolder = (formID) => {
    setFormFolderSelectionModalShow(true);
    setFormFolderSelectionModalFormId(formID);
  };

  const pageStyle = {
    backgroundImage: `url(${background})`,
    backgroundRepeat: "repeat",
    backgroundSize: "150px 150px",
    minHeight: "100vh",
    paddingBottom: "25px",
  };

  return (
    <div style={pageStyle}>
      {profile && !serverError && (
        <div>
          <ThemeNavbar />
          {userForms && (
            <div>
              <div style={{
                display: "flex", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between", paddingLeft: "10px", paddingRight: "20px",
                paddingTop: "20px",
              }}>
                <h1 style={{ fontSize: isMobile ? "1.75em" : "" }}>User Traveller Forms</h1>
                <ThemeButton variant="outline-primary" disabled={userRole !== "admin"} onClick={() => handleAddFormModal(false)}>
                  <div style={{
                    display: "flex", alignItems: "center", gap: "5px", fontSize: isMobile ? "10px" : "16px",
                    whiteSpace: "nowrap"
                  }}>
                    <FaPlus />
                    Make New Form
                  </div>
                </ThemeButton>
              </div>
              <div className="table-container" ref={userFormsTableRef}>
                {isMobile && (
                  <FaAngleRight onClick={() => scrollTableRight(userFormsTableRef)}
                    style={{ fontSize: "15px", zIndex: "100", position: "absolute", color: "#fff", left: "90%", marginTop: "2px" }} />
                )}
                <Table striped bordered hover className="curved-table">
                  <thead style={{ fontSize: isMobile ? "14px" : "20px", height: isMobile ? "40px" : "65px" }}>
                    <tr>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "5%" }}>#</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "7%" }}>Form ID</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "20%" }}>Form Name</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "31%" }}>Form Description</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "37%" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: isMobile ? "10px" : "18px" }}>
                    {userForms.map((structure, index) => (
                      <tr key={index} style={{ height: "60px" }}>
                        <td>{index + 1}</td>
                        <td>{structure.id}</td>
                        <td>{structure.name}</td>
                        <td>{structure.description}</td>
                        <td>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <ThemeButton variant="outline-primary" style={{ height: isMobile ? "25px" : "45px" }}
                              onClick={() => handleViewFormModal(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaEye /> {isMobile ? <></> : <>View</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={userRole !== "admin"} onClick={() => handleEditFormModal(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaPen /> {isMobile ? <></> : <>Edit</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={userRole !== "admin"} onClick={() => handleDownloadJSON(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaDownload /> {isMobile ? <></> : <>Download JSON</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={userRole !== "admin"} onClick={() => handleSelectFolder(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaFolder /> {isMobile ? <></> : <>Add to Folder</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={userRole !== "admin"} onClick={() => handleDeleteForm(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaTrash />
                              </div>
                            </ThemeButton>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          {templateForms && (
            <div>
              <div style={{
                display: "flex", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between", paddingLeft: "10px", paddingRight: "20px",
                paddingTop: "20px",
              }}>
                <h1 style={{ fontSize: isMobile ? "1.75em" : "" }}>Template Traveller Forms</h1>
                <ThemeButton variant="outline-primary" disabled={userRole !== "admin"} onClick={() => handleAddFormModal(true)}>
                  <div style={{
                    display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                    fontSize: isMobile ? "10px" : "16px"
                  }}>
                    <FaPlus />
                    Make Template Forms
                  </div>
                </ThemeButton>
              </div>
              <div className="table-container" ref={templateFormsTableRef}>
                {isMobile && (
                  <FaAngleRight onClick={() => scrollTableRight(templateFormsTableRef)}
                    style={{ fontSize: "15px", zIndex: "100", position: "absolute", color: "#fff", left: "90%", marginTop: "2px" }} />
                )}
                <Table striped bordered hover className="curved-table">
                  <thead style={{ fontSize: isMobile ? "14px" : "20px", height: isMobile ? "40px" : "65px" }}>
                    <tr>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "5%" }}>#</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "7%" }}>Form ID</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "20%" }}>Form Name</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "31%" }}>Form Description</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "37%" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: isMobile ? "10px" : "18px" }}>
                    {templateForms.map((structure, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{structure.id}</td>
                        <td>{structure.name}</td>
                        <td>{structure.description}</td>
                        <td>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <ThemeButton variant="outline-primary" style={{ height: isMobile ? "25px" : "45px", }}
                              onClick={() => handleViewFormModal(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaEye /> {isMobile ? <></> : <>View</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={userRole !== "admin"} onClick={() => handleEditFormModal(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaPen /> {isMobile ? <></> : <>Edit</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={userRole !== "admin"} onClick={() => handleDownloadJSON(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaDownload /> {isMobile ? <></> : <>Download JSON</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={userRole !== "admin"} onClick={() => handleSelectFolder(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaFolder /> {isMobile ? <></> : <>Add to Folder</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={userRole !== "admin"} onClick={() => handleDeleteForm(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaTrash />
                              </div>
                            </ThemeButton>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
          {fields && (
            <div>
              <div style={{
                display: "flex", flexDirection: "row", alignItems: "center",
                justifyContent: "space-between", paddingLeft: "10px", paddingRight: "20px",
                paddingTop: "20px",
              }}>
                <h1 style={{ fontSize: isMobile ? "1.75em" : "" }}>Fields</h1>
                <ThemeButton variant="outline-primary"
                  disabled={userRole !== "admin"} onClick={handleAddFieldModal}>
                  <div style={{
                    display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                    fontSize: isMobile ? "10px" : "16px"
                  }}>
                    <FaPlus />
                    Add Field
                  </div>
                </ThemeButton>
              </div>
              <div className="table-container" ref={fieldsTableRef}>
                {isMobile && (
                  <FaAngleRight onClick={() => scrollTableRight(fieldsTableRef)}
                    style={{ fontSize: "15px", zIndex: "100", position: "absolute", color: "#fff", left: "90%", marginTop: "2px" }} />
                )}
                <Table striped bordered hover className="curved-table">
                  <thead style={{ fontSize: isMobile ? "14px" : "20px", height: isMobile ? "40px" : "65px" }}>
                    <tr>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "5%" }}>#</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "7%" }}>Field ID</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "50%" }}>Question</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "21%" }}>Question Type</th>
                      <th style={{ backgroundColor: "#50011a", color: "white", width: "15%" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: isMobile ? "10px" : "18px" }}>
                    {fields.map((structure, index) => (
                      <tr key={index} style={{ height: "60px" }}>
                        <td>{index + 1}</td>
                        <td>{structure.id}</td>
                        <td>{structure.title}</td>
                        <td>{getQuestionTypeText(structure.type)}</td>
                        <td>
                          <div style={{ display: "flex", gap: "10px" }}>
                            <ThemeButton variant="outline-primary" style={{ height: isMobile ? "25px" : "45px", }}
                              onClick={() => handleViewFieldModal(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaEye /> {isMobile ? <></> : <>View</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              onClick={() => handleEditFieldModal(structure.id)} disabled={userRole !== "admin"}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaPen /> {isMobile ? <></> : <>Edit</>}
                              </div>
                            </ThemeButton>
                            <ThemeButton variant="outline-primary"
                              disabled={userRole !== "admin"} onClick={() => handleDeleteField(structure.id)}>
                              <div style={{
                                display: "flex", alignItems: "center", gap: "5px", whiteSpace: "nowrap",
                                fontSize: isMobile ? "10px" : "16px"
                              }}>
                                <FaTrash />
                              </div>
                            </ThemeButton>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          )}
        </div>
      )}
      {serverError === "401 Unauthorized Access" && serverError && (
        <div className='site-container'>
          <header className="site-header">
            <div className="title-area">
              <h1 className="site-title">
                <a href="https://birdshot.tamu.edu/">Birdshot Center</a>
              </h1>
            </div>
            <div className="widget-area header-widget-area">
              <a id="site-logo" href="https://birdshot.tamu.edu" style={{ display: "block", maxWidth: "350px" }}>
                <img id="custom-header" src="https://birdshot.tamu.edu/wp-content/themes/coe-theme/images/logos/coe/logos-horizontal/ENGINEERING_logo_HWHT.png" alt="Texas A&M University College of Engineering" />
              </a>
            </div>
          </header>
          <body className='site-body'>
            <h2 className='body-title'>Automated Google Web Form Creator and Manager</h2>
            <p className='login-response'>401 Unauthorized Access</p>
            <p>Please <Link to="/" className='link'>Login</Link> to continue.</p>
          </body>
        </div>
      )}
      {serverError && serverError !== "401 Unauthorized Access" && (
        <div>
          <ThemeNavbar></ThemeNavbar>
          <div className='server-error-container'>
            {serverError === "403 Forbidden Access"
              ? (
                <>
                  <p className='login-response'>
                    {serverError}
                  </p>
                  <p>
                    Logout of the website and login again, or ask admin <a href='mailto:attar.v@tamu.edu'>attar.v@tamu.edu</a> for permission
                  </p>
                </>
              )
              : (
                <>
                  <p className='login-response'>
                    {serverError}
                  </p>
                  <p>
                    Contact our development team at <a href='mailto:divyanshu@tamu.edu'>divyanshu@tamu.edu</a>, and <a href='mailto:c2003@tamu.edu'>c2003@tamu.edu</a>
                  </p>
                </>
              )
            }
          </div>
        </div>
      )}
      <ViewFieldModal
        fieldId={viewFieldId}
        show={viewFieldModalShow}
        onHide={() => {
          setViewFieldModalShow(false);
          if (viewFieldModalResponse !== 200) {
            console.log(viewFieldModalResponse);
            handleFetchError(viewFieldModalResponse);
          }
          const userFromStorage = JSON.parse(localStorage.getItem("user"));
          if (!userFromStorage) {
            setServerError("401 Unauthorized Access"); // Set 401 if user is empty
          } else {
            setProfile(userFromStorage);
            fetchFields();
          }
        }}
        setViewFieldModal={setViewFieldModalResponse}
      />
      <EditFieldModal
        fieldId={editFieldId}
        show={editFieldModalShow}
        onHide={() => {
          setEditFieldModalShow(false);
          if (editFieldModalResponse !== 200) {
            handleFetchError(editFieldModalResponse);
          }
          const userFromStorage = JSON.parse(localStorage.getItem("user"));
          if (!userFromStorage) {
            setServerError("401 Unauthorized Access"); // Set 401 if user is empty
          } else {
            setProfile(userFromStorage);
            fetchFields();
          }
        }}
        setViewFieldModal={setEditFieldModalResponse}
      />
      <AddFieldModal
        show={addFieldModalShow}
        onHide={() => {
          setAddFieldModalShow(false);
          if (addFieldModalResponse !== 200) {
            handleFetchError(addFieldModalResponse);
          }
          const userFromStorage = JSON.parse(localStorage.getItem("user"));
          if (!userFromStorage) {
            setServerError("401 Unauthorized Access"); // Set 401 if user is empty
          } else {
            setProfile(userFromStorage);
            fetchFields();
          }
        }}
        setViewFieldModal={setAddFieldModalResponse}
      />
      <ViewFormModal
        formID={viewFormId}
        show={viewFormModalShow}
        onHide={() => {
          setViewFormModalShow(false);
          if (viewFormModalResponse !== 200) {
            handleFetchError(viewFormModalResponse);
          }
          const userFromStorage = JSON.parse(localStorage.getItem("user"));
          if (!userFromStorage) {
            setServerError("401 Unauthorized Access"); // Set 401 if user is empty
          } else {
            setProfile(userFromStorage);
            fetchTemplateForms();
            fetchUserForms();
          }
        }}
        setViewFormModal={setViewFormModalResponse}
      />
      <EditFormModal
        formID={editFormId}
        show={editFormModalShow}
        onHide={() => {
          setEditFormModalShow(false);
          if (editFormModalResponse !== 200) {
            handleFetchError(editFormModalResponse);
          }
          const userFromStorage = JSON.parse(localStorage.getItem("user"));
          if (!userFromStorage) {
            setServerError("401 Unauthorized Access"); // Set 401 if user is empty
          } else {
            setProfile(userFromStorage);
            fetchTemplateForms();
            fetchUserForms();
          }
        }}
        setViewFormModal={setEditFormModalResponse}
      />
      <AddFormModal
        show={addFormModalShow}
        onHide={() => {
          setAddFormModalShow(false);
          if (addFormModalResponse !== 200) {
            handleFetchError(addFormModalResponse);
          }
          const userFromStorage = JSON.parse(localStorage.getItem("user"));
          if (!userFromStorage) {
            setServerError("401 Unauthorized Access"); // Set 401 if user is empty
          } else {
            setProfile(userFromStorage);
            fetchTemplateForms();
            fetchUserForms();
          }
        }}
        setViewFormModal={setAddFormModalResponse}
        isTemplate={addFormModalTemplateValue}
      />
      <FormFolderSelectionModal
        show={formFolderSelectionModalShow}
        onHide={() => {
          setFormFolderSelectionModalShow(false);
          if (formFolderSelectionModalResponse !== 200) {
            handleFetchError(formFolderSelectionModalResponse);
          }
          const userFromStorage = JSON.parse(localStorage.getItem("user"));
          if (!userFromStorage) {
            setServerError("401 Unauthorized Access"); // Set 401 if user is empty
          } else {
            setProfile(userFromStorage);
            fetchTemplateForms();
            fetchUserForms();
          }
        }}
        setFolderSelectorResponse={setFormFolderSelectionModalResponse}
        formID={formFolderSelectionModalFormId}
      />

    </div>
  );
}

export default FormPage;
